import Input from "@material-ui/core/Input";
import styled from "styled-components";

export const Field = styled(Input)<{ error?: boolean }>`
  ${({ theme, ...props }) => `
    border: 2px solid ${theme.palette.error.main};
    border-color: ${props.error ? theme.palette.error.main : "transparent"};
    border-bottom: 0;
    padding: ${theme.spacing(1, 0.5)};
    background: ${theme.palette.background.paper};
  `}
`;
