import styled from "styled-components";
import { LoaderProps } from ".";

export const Root = styled.div<{ color?: LoaderProps["color"] }>`
  ${({ theme, ...props }) => `
    display: flex;
    justify-content: center;
    padding: ${theme.spacing(2)}px;
    width: 100%;
    color: ${
      props.color === "light"
        ? theme.palette.primary.main
        : theme.palette.secondary.main
    };
  `}
`;
