import React from "react";
import { CourseAttributes, TokenAttributes } from "vt-types";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { GridContainer, GridItem } from "../../components/Grid";
import { Loader } from "../../components/Loader";
import { Typography } from "../../components/Typography";
import { captureEvent } from "../../utils/firebase";
import { getLoadButtonLabel } from "../../utils/getters";
import {
  CourseFilterProps,
  FilterCourses,
  SelectOptions,
} from "../FilterBars/FilterCourses";
import { FilterError } from "../FilterBars/FilterError";
import { LoaderWrapper } from "./style";
import { sortByPrice } from "./utils";

interface CourseListProps {
  courses: CourseAttributes[];
  tokens: TokenAttributes[];
}

const showPerPage = 12;
const initialCourseFilters: CourseFilterProps = {
  price: null,
  duration: null,
  level: null,
  authors: [],
  tokens: [],
};

export const CourseList: React.FC<CourseListProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState(1);
  const [courses, setCourses] = React.useState<CourseAttributes[]>(
    props.courses
  );
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [filters, setFilters] = React.useState<CourseFilterProps>({
    price: null,
    duration: null,
    level: null,
    authors: [],
    tokens: [],
  });

  React.useEffect(() => {
    if (isLoading) {
      document
        .getElementById("filter-bar")
        ?.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [isLoading]);

  const onSort = (filters: CourseFilterProps) => {
    setFilters(filters);
    setIsLoading(true);

    let formattedCourses = [...courses];

    if (filters.level) {
      const low = /begin/g;
      const mid = /inter/g;
      const high = /adv/g;

      // @TODO extract this to utils file
      let sortedCourses = formattedCourses.reduce<{
        lowCourses: CourseAttributes[];
        midCourses: CourseAttributes[];
        highCourses: CourseAttributes[];
        otherCourses: CourseAttributes[];
      }>(
        (courses, course) => {
          if (course.level.toLowerCase().match(low)) {
            courses.lowCourses.push(course);
          } else if (course.level.toLowerCase().match(mid)) {
            courses.midCourses.push(course);
          } else if (course.level.toLowerCase().match(high)) {
            courses.highCourses.push(course);
          } else {
            courses.otherCourses.push(course);
          }

          return courses;
        },
        { lowCourses: [], midCourses: [], highCourses: [], otherCourses: [] }
      );

      let { lowCourses, midCourses, highCourses, otherCourses } = sortedCourses;

      if (filters.price) {
        lowCourses = sortByPrice({ courses: lowCourses, order: filters.price });
        midCourses = sortByPrice({ courses: midCourses, order: filters.price });
        highCourses = sortByPrice({
          courses: highCourses,
          order: filters.price,
        });
        otherCourses = sortByPrice({
          courses: otherCourses,
          order: filters.price,
        });
      }

      formattedCourses =
        filters.level === "asc"
          ? [...otherCourses, ...lowCourses, ...midCourses, ...highCourses]
          : [...highCourses, ...midCourses, ...lowCourses, ...otherCourses];
    } else {
      //@TODO Add duration sort
      if (filters.price) {
        formattedCourses = sortByPrice({
          courses: formattedCourses,
          order: filters.price,
        });
      }
    }

    setCourses(formattedCourses);
  };

  const onFilter = async (id: string) => {
    setIsLoading(true);
    const currentTokens = filters.tokens;
    const tokenIndex = currentTokens.findIndex((token) => token === id);
    if (tokenIndex >= 0) {
      currentTokens.splice(tokenIndex, tokenIndex + 1);
    } else {
      currentTokens.push(id);
    }

    setFilters({
      ...filters,
      tokens: currentTokens,
    });
  };

  const onProviderFilter = async (id: string) => {
    setIsLoading(true);

    const currentItems = [...filters.authors];
    const index = currentItems.findIndex((item) => item === id);

    if (index >= 0) {
      currentItems.splice(index, index + 1);
    } else {
      currentItems.push(id);
    }
    setFilters({
      ...filters,
      authors: currentItems,
    });
  };

  const onResetFilters = () => {
    setFilters({ ...initialCourseFilters, tokens: [], authors: [] });
    setSearchTerm("");
  };

  const filteredCourses = courses.filter((course) => {
    const matchedSearch =
      !searchTerm || course.title.toLowerCase().includes(searchTerm);
    const filteredTokens = filters.tokens;
    const filteredProviders = filters.authors;

    const matchedProviders = filteredProviders.includes(course.provider.id);
    const matchedTokens = Boolean(
      course.tokens?.find((token) => filteredTokens.includes(token.id))
    );

    return (
      matchedSearch &&
      (filteredTokens.length <= 0 || matchedTokens) &&
      (filteredProviders.length <= 0 || matchedProviders)
    );
  });

  return (
    <div id="filter-bar">
      <FilterCourses
        dirty={JSON.stringify(filters) !== JSON.stringify(initialCourseFilters)}
        count={filteredCourses.length}
        onReset={onResetFilters}
        onSearch={(term) => {
          setIsLoading(true);
          setSearchTerm(term);
        }}
        filters={filters}
        onSort={onSort}
        onFilter={onFilter}
        onProviderFilter={onProviderFilter}
        providers={props.courses.reduce<SelectOptions[]>(
          (providers, course) => {
            if (
              providers.every(
                (provider) => provider.value !== course.provider.id
              )
            ) {
              const { provider } = course;
              return [
                ...providers,
                {
                  value: provider.id,
                  label: provider.name,
                  count: courses.filter(
                    (course) => course.provider.id === provider.id
                  ).length,
                  selected: Boolean(
                    filters.authors?.find(
                      (filterAuthor) => filterAuthor === provider.id
                    ) ?? false
                  ),
                },
              ];
            }
            return providers;
          },
          []
        )}
        tokens={props.tokens.reduce<SelectOptions[]>((acc, token) => {
          const courseLength = courses.filter((course) =>
            course.tokens?.find((courseToken) => courseToken.id === token.id)
          ).length;

          return [
            ...acc,
            {
              value: token.id,
              label: token.name,
              count: courseLength,
              selected: Boolean(
                filters.tokens?.find(
                  (filterToken) => filterToken === token.id
                ) ?? false
              ),
              // disabled: courseLength <= 0,
            },
          ];
        }, [])}
      />

      <GridContainer wrap="wrap">
        {filteredCourses.length > 0 && (
          <GridItem>
            <Typography variant="overline">
              <strong>
                Showing {filteredCourses.length} course
                {filteredCourses.length !== 1 ? "s" : ""}
              </strong>
            </Typography>
          </GridItem>
        )}

        {isLoading ? (
          <GridItem>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </GridItem>
        ) : (
          <React.Fragment>
            {filteredCourses.length <= 0 ? (
              <GridItem>
                <FilterError name="courses" />
              </GridItem>
            ) : (
              <React.Fragment>
                {filteredCourses.map((course, index) => (
                  <React.Fragment key={course.id}>
                    {pagination * showPerPage > index && (
                      <GridItem md={6}>
                        <Card
                          onClick={async () => {
                            await captureEvent({
                              category: "affiliate-course",
                              value: `${course.id}`,
                            });
                            window.open(course.url, "_blank");
                          }}
                          title={course.title}
                          body={course.description}
                          stats={{
                            provider: course.provider,
                            duration: course.duration,
                            level: course.level,
                            price: course.signup
                              ? "Enrol"
                              : course.price <= 0
                              ? "Free"
                              : `${course.price}`,
                          }}
                        />
                      </GridItem>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}

            {pagination * showPerPage < filteredCourses.length && (
              <GridItem>
                <Button
                  color="secondary"
                  onClick={async () => {
                    await captureEvent({
                      value: "more courses",
                    });

                    setPagination(pagination + 1);
                  }}
                >
                  {getLoadButtonLabel(pagination)}
                </Button>
              </GridItem>
            )}
          </React.Fragment>
        )}
      </GridContainer>
    </div>
  );
};
