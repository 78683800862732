import React from "react";
import { HygienicPage } from ".";
import { Link } from "../../components/Link";
import { Typography } from "../../components/Typography";
import { config } from "../../utils/config";
import { companyName } from "../../utils/getters";

export const Privacy: React.FC = () => {
  return (
    <HygienicPage title="Privacy Policy">
      <Typography paragraph={true}>
        This privacy policy sets out how {companyName} uses and protects any
        information that you give when you use this website.
      </Typography>
      <Typography paragraph={true}>
        {companyName} is committed to ensuring that your privacy is protected.
        Should we ask you to provide certain information by which you can be
        identified when using this website, then you can be assured that it will
        only be used in accordance with this privacy statement.
      </Typography>
      <Typography paragraph={true}>
        {companyName} may change this policy from time to time by updating this
        page. You should check this page from time to time to ensure that you
        are happy with any changes. This policy is effective from 5 January
        2018.
      </Typography>

      <Typography variant="h3" component="h2" gutterBottom={true}>
        What we collect
      </Typography>

      <Typography>
        We <strong>may</strong> collect the following information:
      </Typography>
      <ul>
        <li>
          <Typography>name and job title</Typography>
        </li>
        <li>
          <Typography>contact information including email address</Typography>
        </li>
        <li>
          <Typography>
            demographic information such as postcode, preferences and interests
          </Typography>
        </li>
        <li>
          <Typography>
            other information relevant to customer surveys and/or offers
          </Typography>
        </li>
      </ul>

      <Typography variant="h4" gutterBottom={true}>
        What we do with the information we gather
      </Typography>

      <Typography>
        We require this information to understand your needs and provide you
        with a better service, and in particular for the following reasons:
      </Typography>

      <ul>
        <li>
          <Typography>Internal record keeping.</Typography>
        </li>
        <li>
          <Typography>
            We may use the information to improve our products and services.
          </Typography>
        </li>
        <li>
          <Typography>
            We may periodically send promotional emails about new products,
            special offers or other information which we think you may find
            interesting using the email address which you have provided.
          </Typography>
        </li>
        <li>
          <Typography>
            From time to time, we may also use your information to contact you
            for market research purposes by email. We may use the information to
            customise the website according to your interests.
          </Typography>
        </li>
      </ul>

      <Typography variant="h3" component="h2" gutterBottom={true}>
        Security
      </Typography>

      <Typography paragraph={true}>
        We're committed to ensuring that your information is secure. In order to
        prevent unauthorised access or disclosure, we have put in place suitable
        physical, electronic and managerial procedures to safeguard and secure
        the information we collect online.
      </Typography>

      <Typography variant="h3" component="h2" gutterBottom={true}>
        How we use cookies
      </Typography>

      <Typography paragraph={true}>
        A cookie is a small file which asks permission to be placed on your
        computer's hard drive to help analyse web traffic or lets you know when
        you visit a particular site. Cookies allow web applications to respond
        to you as an individual. The web application can tailor its operations
        to your needs, likes and dislikes by gathering and remembering
        information about your preferences.
      </Typography>

      <Typography paragraph={true}>
        We use traffic log cookies to identify which pages are being used. This
        helps us analyse data about web page traffic and improve our website in
        order to tailor it to customer needs. We only use this information for
        statistical analysis purposes and then the data is removed from the
        system.
      </Typography>

      <Typography paragraph={true}>
        Overall, cookies help us provide you with a better website, by enabling
        us to monitor which pages you find useful and which you do not. A cookie
        in no way gives us access to your computer or any information about you,
        other than the data you choose to share with us.
      </Typography>
      {/*
      <Typography paragraph={true}>
        You can choose to accept or decline cookies. Most web browsers
        automatically accept cookies, but you can usually modify your browser
        setting to decline cookies if you prefer. This may prevent you from
        taking full advantage of the website.
      </Typography> */}

      <Typography variant="h3" component="h2" gutterBottom={true}>
        Links to other websites
      </Typography>

      <Typography paragraph={true}>
        Our website may contain links to other websites of interest. However,
        once you have used these links to leave our site, you should note that
        we do not have any control over that other website. Therefore, we cannot
        be responsible for the protection and privacy of any information which
        you provide whilst visiting such sites and such sites are not governed
        by this privacy statement. You should exercise caution and look at the
        privacy statement applicable to the website in question.
      </Typography>

      <Typography variant="h3" component="h2" gutterBottom={true}>
        Controlling your personal information
      </Typography>

      <Typography>
        You may choose to restrict the collection or use of your personal
        information in the following ways
      </Typography>

      <ul>
        <li>
          <Typography>
            whenever you are asked to fill in a form on the website, look for
            the box that you can click to indicate that you do not want the
            information to be used by anybody for direct marketing purposes{" "}
          </Typography>
        </li>
        <li>
          <Typography>
            if you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time
            by emailing us at{" "}
            <Link href={`mailto:${config.contactInfo.privacyEmail}`}>
              {config.contactInfo.privacyEmail}
            </Link>
          </Typography>
        </li>
      </ul>

      <Typography paragraph={true}>
        We will not sell, distribute or lease your personal information to third
        parties unless we have your permission or are required by law to do so.
        We may use your personal information to send you promotional information
        about third parties which we think you may find interesting if you tell
        us that you wish this to happen.
      </Typography>

      <Typography paragraph={true}>
        You may request details of personal information which we hold about you
        under the Data Protection Act 1998. A small fee will be payable. If you
        would like a copy of the information held on you please write to{" "}
        <Link href={`mailto:${config.contactInfo.privacyEmail}`}>
          {config.contactInfo.privacyEmail}
        </Link>
      </Typography>

      <Typography paragraph={true}>
        If you believe that any information we're holding on you is incorrect or
        incomplete, please email us at{" "}
        <Link href={`mailto:${config.contactInfo.privacyEmail}`}>
          {config.contactInfo.privacyEmail}
        </Link>{" "}
        as soon as possible. We will promptly correct any information found to
        be incorrect.
      </Typography>
    </HygienicPage>
  );
};
