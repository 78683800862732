import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/Button";
import { Layout } from "../../containers/Layout";
import { Typography } from "../../components/Typography";
import { RouteOptions } from "../../utils/routes";
import { homeButtonLabel } from "../../utils/getters";

interface HygienicPageProps {
  title: string;
  children: React.ReactNode;
}

export const HygienicPage: React.FC<HygienicPageProps> = (props) => {
  const history = useHistory();
  return (
    <Layout>
      <Typography variant="h1" gutterBottom={true}>
        {props.title}
      </Typography>
      {props.children}
      <Button onClick={() => history.push(RouteOptions.Home)}>
        {homeButtonLabel}
      </Button>
    </Layout>
  );
};
