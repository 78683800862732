import React from "react";

interface EmojiProps {
  label: string;
  value: string;
}
export const Emoji: React.FC<EmojiProps> = (props) => {
  return (
    <span aria-label={`Emoji of ${props.label}`} role="img">
      {" "}
      {props.value}{" "}
    </span>
  );
};
