import { useQuery } from "@apollo/react-hooks";
import CoursesIcon from "@material-ui/icons/BookOutlined";
import TokenIcon from "@material-ui/icons/BeenhereOutlined";
import { gql } from "apollo-boost";
import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "../../components/Container";
import { makeRolesUrl, RouteOptions } from "../../utils/routes";
import { Sidebar } from "../Sidebar";
import { NavAttributes } from "../Sidebar/Nav";
import { Root } from "./style";

interface LayoutProps {
  navItems?: NavAttributes[];
  omitContainer?: boolean;
}

const query = gql`
  query populateNav {
    roles {
      identifier
      name
    }
  }
`;

export const Layout: React.FC<LayoutProps> = (props) => {
  const { data } = useQuery<{
    roles: Array<{ identifier: string; name: string }>;
  }>(query, {
    skip: props.navItems !== undefined,
  });

  const navItems = props.navItems ?? [
    {
      value: RouteOptions.Tokens,
      selected: RouteOptions.Tokens === window.location.pathname,
      label: "Explore Tokens",
      icon: <TokenIcon color="inherit" fontSize="inherit" />,
    },

    {
      value: RouteOptions.Courses,
      selected: RouteOptions.Courses === window.location.pathname,
      label: "browse courses",
      icon: <CoursesIcon color="inherit" fontSize="inherit" />,
    },
    ...(data?.roles.map((role) => ({
      value: makeRolesUrl(role.identifier),
      label: role.name,
    })) ?? []),
  ];

  return (
    <Sidebar items={navItems}>
      <Helmet>
        <title>VerifiedToken</title>
        <meta
          name="description"
          content="Educate & Empower our members in their search for work|life balance."
        />
      </Helmet>

      <Root>
        {props.omitContainer ? (
          <React.Fragment>{props.children}</React.Fragment>
        ) : (
          <Container>{props.children}</Container>
        )}
      </Root>
    </Sidebar>
  );
};
