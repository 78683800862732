import React from "react";
import { Logo, ForwardIcon, BackIcon, FilterIcon } from "./style";

interface IconProps {
  variant: "back" | "forward" | "filter";
  selected?: boolean;
  color?: "primary" | "secondary";
}

export const Icon: React.FC<IconProps> = (props) => {
  switch (props.variant) {
    case "filter":
      return (
        <FilterIcon open={props.selected} fontSize="inherit" color="inherit" />
      );

    case "forward":
      return <ForwardIcon color={props.color} />;
    case "back":
      return <BackIcon color={props.color} />;
    default:
      return <Logo color={props.color} />;
  }
};
