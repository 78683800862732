import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { RoleAttributes } from "vt-types";
import { Button } from "../../components/Button";
import { Container } from "../../components/Container";
import { GridContainer, GridItem } from "../../components/Grid";
import { HorizontalStat } from "../../components/HorizontalStat";
import { Link } from "../../components/Link";
import { Loader } from "../../components/Loader";
import { Logo } from "../../components/Logo";
import { Typography } from "../../components/Typography";
import { GetRolesDataQuery, GET_ROLES_DATA } from "../../queries";
import { captureEvent } from "../../utils/firebase";
import { getRoleStats } from "../../utils/getters";
import { makeRolesUrl, RouteOptions } from "../../utils/routes";
import {
  Banner,
  BannerContent,
  Card,
  CardButton,
  CTA,
  Heading,
  LogoWrapper,
  Subtitle,
  Title,
  CardContent,
} from "./Roles.styles";

interface ScreenProps extends RouteComponentProps {}

export const Roles: React.FC<ScreenProps> = (props) => {
  const { loading, error, data } = useQuery<GetRolesDataQuery>(GET_ROLES_DATA);

  return (
    <div style={{ height: "100vh" }}>
      <Banner elevation={0}>
        <BannerContent>
          <Title variant="h1" align="center">
            <LogoWrapper>
              <Logo color="light" variant="icon" />
            </LogoWrapper>
            <span>erifiedToken</span>
          </Title>

          <Subtitle variant="subtitle1" component="p" align="center">
            <strong>Your ticket into tech starts with code</strong>
          </Subtitle>

          <CTA
            color="secondary"
            onClick={() => props.history.push(RouteOptions.Tokens)}
          >
            Explore Tokens
          </CTA>
        </BannerContent>
      </Banner>
      <Container>
        <GridContainer>
          <GridItem>
            <Typography
              variant="h1"
              component="h2"
              align="center"
              gutterBottom={true}
            >
              Tech Roles
            </Typography>

            <Typography component="p" align="center">
              <strong>Let's start with the obvious ones</strong>
            </Typography>
          </GridItem>

          {loading || error || !data ? (
            <GridItem style={{ marginTop: "2rem" }}>
              <Loader
                error={
                  error ? "Unable to retrieve roles right now 😰" : undefined
                }
              />
            </GridItem>
          ) : (
            <React.Fragment>
              {data.roles.map((role) => (
                <GridItem md={6} key={role.id}>
                  <Card>
                    <Heading
                      variant="h2"
                      component="h3"
                      align="center"
                      gutterBottom={true}
                    >
                      <Link
                        noDecoration={true}
                        href={makeRolesUrl(role.identifier)}
                      >
                        {role.name}
                      </Link>
                    </Heading>

                    <CardContent align="center" paragraph={true}>
                      {role.description}
                    </CardContent>

                    <HorizontalStat
                      id="role"
                      items={getRoleStats(role as RoleAttributes)}
                    />

                    <Button
                      onClick={async () => {
                        await captureEvent({
                          value: role.name,
                          category: "browse",
                        });
                        props.history.push(makeRolesUrl(role.identifier));
                      }}
                    >
                      browse courses
                    </Button>
                  </Card>
                </GridItem>
              ))}
              <GridItem md={6}>
                <CardButton
                  onClick={() => props.history.push(RouteOptions.RequestRole)}
                >
                  suggest a role
                </CardButton>
              </GridItem>
            </React.Fragment>
          )}

          <GridItem>
            <Button
              state="inverted"
              color="secondary"
              onClick={() => props.history.push(RouteOptions.Courses)}
            >
              browse courses
            </Button>
          </GridItem>
        </GridContainer>
      </Container>
    </div>
  );
};
