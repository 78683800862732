import React from "react";
import { IconButtonProps, SvgIconTypeMap } from "@material-ui/core";
import { Root } from "./style";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

interface ButtonIconProps {
  onClick: () => void;
  state?: "inverted" | "disabled";
  size?: "lg";
  color?: "secondary" | "error";
  action?: string;
  edge?: IconButtonProps["edge"];
  Icon?: OverridableComponent<SvgIconTypeMap>;
  children?: React.ReactNode;
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({ Icon, ...props }) => {
  if (!Icon && !props.children) {
    throw new Error("Must supply icon when using ButtonIcon");
  }
  const size = props.size === "lg" ? "medium" : "small";
  const color = props.color ?? "primary";
  const state = props.state ?? "default";

  return (
    <Root
      edge={props.edge ?? false}
      disabled={state === "disabled"}
      size={size}
      vcolor={color}
      state={state}
      aria-label={props.action}
      onClick={props.onClick}
    >
      {Icon ? (
        <Icon fontSize="inherit" color="inherit" />
      ) : (
        <React.Fragment>{props.children}</React.Fragment>
      )}
    </Root>
  );
};
