import React from "react";
import { Root } from "./style";
import { Typography } from "../Typography";
import { LinearProgressProps } from "@material-ui/core/LinearProgress";

interface ProgressBarProps extends LinearProgressProps {
  value: number;
  label: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  label,
  ...props
}) => {
  return (
    <React.Fragment>
      <Root variant="determinate" value={value} {...props} />
      <Typography
        variant="overline"
        align="right"
        component="p"
        paragraph={true}
      >
        <strong>{label}</strong>
      </Typography>
    </React.Fragment>
  );
};
