import Container from "@material-ui/core/Container";
import styled from "styled-components";
import { containerWidth } from "../../utils/theme";

export const Root = styled(Container)`
  ${({ theme }) => `
    padding: ${theme.spacing(2, 2)};
    max-width: ${containerWidth}px;
    position: relative;
  `}
`;
