import React from "react";
import { theme } from "../../utils/theme";
import styled from "styled-components";

interface LogoVariantProps {
  fill: string;
}

const LogoIcon: React.FC<LogoVariantProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 287 301.87">
    <polygon
      fill={props.fill}
      points="143.5 301.87 0.71 15.59 286.29 15.59 143.5 301.87"
    />
    <rect fill={props.fill} width="287" height="5" />
  </svg>
);

const LogoMark: React.FC<LogoVariantProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 404.09 302">
    <polygon
      fill={props.fill}
      points="143.44 302.72 0 15.86 286.87 15.86 143.44 302.72"
    />
    <path
      fill={props.fill}
      d="M396.09,20.86v47h-87v230h-48v-230h-87v-47h222m5-5h-232v57h87v230h58v-230h87v-57Z"
    />
    <rect fill={props.fill} x="0.09" width="404" height="5" />
  </svg>
);

const LogoFull: React.FC<LogoVariantProps> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 912.6 118.2">
    <path
      fill={props.fill}
      d="M614.78,20V33.91H584.91v79.68H571V33.91H541.1V20h73.68m3-3H538.1V36.91H568v79.68h19.87V36.91h29.87V17Z"
    />

    <path
      fill={props.fill}
      d="M648.55,42.34a36.11,36.11,0,0,1,13.7,2.59,33.14,33.14,0,0,1,11.06,7.33,35,35,0,0,1,7.48,11.46,39.31,39.31,0,0,1,2.76,15,37.24,37.24,0,0,1-2.76,14.37,35.84,35.84,0,0,1-7.5,11.49,35.18,35.18,0,0,1-11.11,7.62,34.75,34.75,0,0,1-27.19,0A35.13,35.13,0,0,1,616.31,93.1a37.23,37.23,0,0,1-2.75-14.37,37.76,37.76,0,0,1,2.76-14.56,35.56,35.56,0,0,1,7.52-11.5A35.08,35.08,0,0,1,635,45.08a34,34,0,0,1,13.57-2.74m0,59.6a21.71,21.71,0,0,0,8.5-1.69,21.4,21.4,0,0,0,7-4.77,22.42,22.42,0,0,0,4.7-7.38,26.66,26.66,0,0,0,0-18.79A22.67,22.67,0,0,0,664.05,62a20.41,20.41,0,0,0-7-4.79,22.46,22.46,0,0,0-16.95,0,20.21,20.21,0,0,0-7,4.8,22.81,22.81,0,0,0-4.61,7.36,25.77,25.77,0,0,0-1.66,9.4,24.52,24.52,0,0,0,1.67,9.09,23.1,23.1,0,0,0,4.59,7.34,21.81,21.81,0,0,0,6.92,5,20.52,20.52,0,0,0,8.57,1.83m0-62.6a36.84,36.84,0,0,0-14.76,3,37.86,37.86,0,0,0-12.09,8.23A38.43,38.43,0,0,0,613.54,63a40.72,40.72,0,0,0-3,15.7,40,40,0,0,0,3,15.52A38,38,0,0,0,633.79,115a37.64,37.64,0,0,0,29.59,0,37.92,37.92,0,0,0,12.06-8.26,39.1,39.1,0,0,0,8.13-12.44,40,40,0,0,0,3-15.52,42.08,42.08,0,0,0-3-16.15,38.12,38.12,0,0,0-8.13-12.44,36,36,0,0,0-12.06-8,39,39,0,0,0-14.83-2.81Zm0,59.6a17.57,17.57,0,0,1-7.33-1.56,18.75,18.75,0,0,1-6-4.27,20.09,20.09,0,0,1-4-6.39,21.38,21.38,0,0,1-1.46-8,22.39,22.39,0,0,1,1.46-8.3,19.45,19.45,0,0,1,4-6.39,17.13,17.13,0,0,1,6-4.1,19.5,19.5,0,0,1,14.66,0,17.48,17.48,0,0,1,6,4.1A19.61,19.61,0,0,1,666,70.43,23.6,23.6,0,0,1,666,87a19,19,0,0,1-4.06,6.39,18,18,0,0,1-6,4.1,18.41,18.41,0,0,1-7.33,1.46Z"
    />
    <path
      fill={props.fill}
      d="M713.15,15.6V86.82L718.5,80l27.37-34.7H760L739.22,71.44,738,73l1,1.77,22.11,38.79H746.18L732,88.1l-2.24-4L727,87.76l-13.2,17.5-.6.81v7.52H700v-98h13.11m3-3H697v104h19.11v-9.52l13.19-17.51,15.08,27h21.81L741.57,73.31l24.66-31H744.42L716.15,78.17V12.6Z"
    />

    <path
      fill={props.fill}
      d="M805.48,42.34a34.44,34.44,0,0,1,8.37,1,35.07,35.07,0,0,1,7.78,3A35.64,35.64,0,0,1,828.52,51a34.24,34.24,0,0,1,4.26,4.42l-34.06,41-2.85,3.44,4.26,1.34a13.62,13.62,0,0,0,2.84.57c.83.08,1.67.12,2.51.12a21.72,21.72,0,0,0,6.2-.89,22.44,22.44,0,0,0,5.58-2.52,21.08,21.08,0,0,0,4.69-4,19.39,19.39,0,0,0,1.26-1.61l9.28,9.33a33.68,33.68,0,0,1-4.07,4.2,35.56,35.56,0,0,1-6.86,4.68,34.12,34.12,0,0,1-7.73,2.9,35.66,35.66,0,0,1-22-1.68,34.63,34.63,0,0,1-11.12-7.48,35.29,35.29,0,0,1-7.52-11.44,38,38,0,0,1-2.76-14.65,39.45,39.45,0,0,1,2.76-15,34.51,34.51,0,0,1,7.5-11.44A34.12,34.12,0,0,1,791.86,45a35.56,35.56,0,0,1,13.62-2.62M788.32,94l2.74-3.63,22.09-29.32,2.43-3.22-3.79-1.4a14.34,14.34,0,0,0-3.31-.79,29.06,29.06,0,0,0-3-.16A22,22,0,0,0,797,57.17a20.41,20.41,0,0,0-7,4.75,22.36,22.36,0,0,0-4.62,7.34,25.75,25.75,0,0,0-1.67,9.47c0,.77,0,1.64.12,2.6a27.55,27.55,0,0,0,.38,3c.18,1,.4,2,.65,2.88a12.94,12.94,0,0,0,1.19,2.91l2.26,4m17.16-54.69a38.31,38.31,0,0,0-14.76,2.85,36.42,36.42,0,0,0-20.25,20.42,42.18,42.18,0,0,0-3,16.12,40.8,40.8,0,0,0,3,15.8,38,38,0,0,0,8.16,12.4,37.57,37.57,0,0,0,26.85,11,38,38,0,0,0,9.06-1.08,37.21,37.21,0,0,0,8.41-3.16,38.43,38.43,0,0,0,7.43-5.07,37.13,37.13,0,0,0,6.08-6.74L822.57,88a18.17,18.17,0,0,1-6.91,8,19.49,19.49,0,0,1-4.83,2.19,18.7,18.7,0,0,1-5.35.76c-.74,0-1.48,0-2.22-.1a10.89,10.89,0,0,1-2.23-.45L836.6,55.53a38.18,38.18,0,0,0-6.11-6.78,38.88,38.88,0,0,0-7.47-5.1,38.16,38.16,0,0,0-8.44-3.2,37.42,37.42,0,0,0-9.1-1.11ZM788.67,88.59a10.2,10.2,0,0,1-.91-2.22c-.23-.83-.42-1.7-.59-2.61s-.27-1.79-.34-2.67-.11-1.67-.11-2.36a22.78,22.78,0,0,1,1.46-8.37,19,19,0,0,1,4-6.36,17.44,17.44,0,0,1,6-4.06,18.93,18.93,0,0,1,7.33-1.43,25.48,25.48,0,0,1,2.67.14,11,11,0,0,1,2.61.63L788.67,88.59Z"
    />
    <path
      fill={props.fill}
      d="M879.46,43.73a29.26,29.26,0,0,1,11.67,2.36,30.53,30.53,0,0,1,9.61,6.51,31.3,31.3,0,0,1,6.5,9.7,29.84,29.84,0,0,1,2.36,11.77v39.52h-13V74.07a17,17,0,0,0-1.35-6.7,17.3,17.3,0,0,0-9.14-9.18,16.8,16.8,0,0,0-6.68-1.36,17.51,17.51,0,0,0-6.79,1.35,17.12,17.12,0,0,0-9.23,9.19,16.93,16.93,0,0,0-1.36,6.7v39.52h-13V45.19h.21l5.36,6.19,2,2.32L859,51.63a30.36,30.36,0,0,1,20.49-7.9m0-3A33.43,33.43,0,0,0,857,49.41l-6.25-7.22h-4.58v74.4h19V74.07a14,14,0,0,1,1.11-5.52A14.24,14.24,0,0,1,869.25,64a14.4,14.4,0,0,1,10.21-4.17A13.67,13.67,0,0,1,885,60.94,14.8,14.8,0,0,1,889.47,64a14.38,14.38,0,0,1,3,4.55,14,14,0,0,1,1.11,5.52v42.52h19V74.07A32.47,32.47,0,0,0,910,61.12a33.93,33.93,0,0,0-7.12-10.63,33.39,33.39,0,0,0-10.56-7.16,32.22,32.22,0,0,0-12.85-2.6Z"
    />
    <path
      fill={props.fill}
      d="M137.11,42.34a34.5,34.5,0,0,1,8.37,1,35.07,35.07,0,0,1,7.78,3A35.64,35.64,0,0,1,160.15,51a34.92,34.92,0,0,1,4.26,4.42l-34.06,41-2.85,3.44,4.26,1.34a13.62,13.62,0,0,0,2.84.57c.83.08,1.67.12,2.51.12a21.72,21.72,0,0,0,6.2-.89,22.44,22.44,0,0,0,5.58-2.52,21.08,21.08,0,0,0,4.69-4,19.39,19.39,0,0,0,1.26-1.61l9.28,9.33a34.39,34.39,0,0,1-4.07,4.2,36,36,0,0,1-6.86,4.68,34.12,34.12,0,0,1-7.73,2.9,35.21,35.21,0,0,1-8.35,1,34.51,34.51,0,0,1-24.72-10.16,35.29,35.29,0,0,1-7.52-11.44,38,38,0,0,1-2.76-14.65,39.37,39.37,0,0,1,2.76-15,34.36,34.36,0,0,1,7.5-11.44A33.91,33.91,0,0,1,123.49,45a35.56,35.56,0,0,1,13.62-2.62M120,94l2.73-3.63,22.09-29.32,2.43-3.22-3.79-1.4a14.34,14.34,0,0,0-3.31-.79,29.06,29.06,0,0,0-3-.16,22,22,0,0,0-8.48,1.66,20.41,20.41,0,0,0-7,4.75A22.36,22.36,0,0,0,117,69.26a25.75,25.75,0,0,0-1.67,9.47c0,.76,0,1.64.12,2.6a27.55,27.55,0,0,0,.38,3c.18,1,.4,2,.65,2.88a12.57,12.57,0,0,0,1.19,2.91L120,94m17.15-54.69a38.28,38.28,0,0,0-14.76,2.85A36.42,36.42,0,0,0,102.1,62.61a42,42,0,0,0-3,16.12,40.62,40.62,0,0,0,3,15.8,38,38,0,0,0,8.16,12.4,37.57,37.57,0,0,0,26.85,11,38,38,0,0,0,9.06-1.08,37.21,37.21,0,0,0,8.41-3.16,38.43,38.43,0,0,0,7.43-5.07,37.5,37.5,0,0,0,6.08-6.74L154.2,88a18.34,18.34,0,0,1-6.91,8,19.49,19.49,0,0,1-4.83,2.19,18.7,18.7,0,0,1-5.35.76c-.74,0-1.48,0-2.22-.1a10.89,10.89,0,0,1-2.23-.45l35.57-42.86a38.18,38.18,0,0,0-6.11-6.78,38.88,38.88,0,0,0-7.47-5.1,38.35,38.35,0,0,0-8.44-3.2,37.48,37.48,0,0,0-9.1-1.11ZM120.3,88.59a10.2,10.2,0,0,1-.91-2.22c-.23-.83-.42-1.7-.59-2.61s-.27-1.79-.34-2.67-.11-1.67-.11-2.36a22.78,22.78,0,0,1,1.46-8.37,19,19,0,0,1,4-6.36,17.44,17.44,0,0,1,6-4.06,18.93,18.93,0,0,1,7.33-1.43,25.48,25.48,0,0,1,2.67.14,11,11,0,0,1,2.61.63L120.3,88.59Z"
    />
    <path
      fill={props.fill}
      d="M180.78,45.19l5.35,7.56,2,2.76,2.51-2.28a30.46,30.46,0,0,1,20.49-7.9h13.74V58.22H211.09a17.47,17.47,0,0,0-6.79,1.35,17.29,17.29,0,0,0-10.59,16v38h-13V45.19h0m1.55-3h-4.58v74.4h19v-41a14.4,14.4,0,0,1,4.17-10.21,14.05,14.05,0,0,1,4.58-3.06,14.39,14.39,0,0,1,5.63-1.11h16.74V42.33H211.09A33.58,33.58,0,0,0,188.58,51l-6.25-8.82Z"
    />
    <path
      fill={props.fill}
      d="M246.24,12.33a9.3,9.3,0,0,1,3.77.76,10.33,10.33,0,0,1,3.16,2.1,9.49,9.49,0,0,1,2.09,3.08,9.52,9.52,0,0,1,0,7.43,9.64,9.64,0,0,1-2.1,3.06,10.59,10.59,0,0,1-3.15,2.1,9.78,9.78,0,0,1-7.56,0,9.71,9.71,0,0,1-5.18-5.16,9,9,0,0,1-.75-3.73,9.14,9.14,0,0,1,.76-3.7,9.74,9.74,0,0,1,2.07-3.1,10,10,0,0,1,3.11-2.08,9.3,9.3,0,0,1,3.77-.76m6.52,32.86v68.4H239.65V45.19h13.11M246.24,9.33a12.41,12.41,0,0,0-5,1,12.82,12.82,0,0,0-4,2.71,13,13,0,0,0-2.71,4,12.58,12.58,0,0,0,0,9.83,13.22,13.22,0,0,0,2.71,4,12.82,12.82,0,0,0,4,2.71,12.73,12.73,0,0,0,9.94,0,13.19,13.19,0,0,0,4.06-2.71,12.62,12.62,0,0,0,2.74-4,12.46,12.46,0,0,0,0-9.83,12.46,12.46,0,0,0-2.74-4,13.19,13.19,0,0,0-4.06-2.71,12.44,12.44,0,0,0-5-1Zm9.52,32.86H236.65v74.4h19.11V42.19Z"
    />
    <path
      fill={props.fill}
      d="M319.86,8.51v12.9H308.62a17.69,17.69,0,0,0-6.81,1.3,16.88,16.88,0,0,0-5.49,3.64,17.23,17.23,0,0,0-5,12.3v6.54h23.27V58.08H291.31v55.51h-13V58.08h-9.24V45.19h9.24V38.65a29.42,29.42,0,0,1,2.36-11.74,30,30,0,0,1,6.5-9.57,31.42,31.42,0,0,1,9.68-6.47,29.37,29.37,0,0,1,11.73-2.36h11.24m3-3H308.62A32.25,32.25,0,0,0,295.7,8.12a34,34,0,0,0-10.59,7.08A32.87,32.87,0,0,0,278,25.73a32.18,32.18,0,0,0-2.6,12.92v3.54h-9.24V61.08h9.24v55.51h19V61.08h23.27V42.19H294.31V38.65a14.22,14.22,0,0,1,4.13-10.18,13.87,13.87,0,0,1,4.52-3,14.82,14.82,0,0,1,5.66-1.07h14.24V5.51Z"
    />
    <path
      fill={props.fill}
      d="M338.84,12.33a9.3,9.3,0,0,1,3.77.76,10.33,10.33,0,0,1,3.16,2.1,9.49,9.49,0,0,1,2.09,3.08,9.52,9.52,0,0,1,0,7.43,9.64,9.64,0,0,1-2.1,3.06,10.51,10.51,0,0,1-3.16,2.1,9.76,9.76,0,0,1-7.55,0,9.71,9.71,0,0,1-5.18-5.16,9,9,0,0,1-.75-3.73,9.14,9.14,0,0,1,.76-3.7,9.74,9.74,0,0,1,2.07-3.1,10,10,0,0,1,3.1-2.08,9.39,9.39,0,0,1,3.78-.76m6.52,32.86v68.4H332.25V45.19h13.11M338.84,9.33a12.41,12.41,0,0,0-5,1,12.63,12.63,0,0,0-6.74,6.74,12.58,12.58,0,0,0,0,9.83,13,13,0,0,0,2.71,4,12.82,12.82,0,0,0,4,2.71,12.73,12.73,0,0,0,9.94,0,13.19,13.19,0,0,0,4.06-2.71,12.62,12.62,0,0,0,2.74-4,12.46,12.46,0,0,0,0-9.83,12.46,12.46,0,0,0-2.74-4,13.19,13.19,0,0,0-4.06-2.71,12.44,12.44,0,0,0-5-1Zm9.52,32.86H329.25v74.4h19.11V42.19Z"
    />
    <path
      fill={props.fill}
      d="M396.84,42.34a34.58,34.58,0,0,1,8.38,1A35.1,35.1,0,0,1,419.88,51a33.58,33.58,0,0,1,4.26,4.42l-34.05,41-2.85,3.44,4.26,1.34a13.37,13.37,0,0,0,2.84.57c.82.08,1.66.12,2.5.12a21.78,21.78,0,0,0,6.21-.89,22.37,22.37,0,0,0,5.57-2.52,21.35,21.35,0,0,0,4.7-4c.44-.52.86-1,1.26-1.61l9.28,9.33a34.47,34.47,0,0,1-4.08,4.2,35.64,35.64,0,0,1-6.85,4.68,34.12,34.12,0,0,1-7.73,2.9,35.23,35.23,0,0,1-8.36,1,34.56,34.56,0,0,1-24.72-10.16,35.29,35.29,0,0,1-7.52-11.44,38,38,0,0,1-2.75-14.65,39.18,39.18,0,0,1,2.76-15,34.36,34.36,0,0,1,7.5-11.44A34,34,0,0,1,383.22,45a35.6,35.6,0,0,1,13.62-2.62M379.69,94l2.74-3.63,22.09-29.32L407,57.86l-3.79-1.4a14.42,14.42,0,0,0-3.32-.79,29.06,29.06,0,0,0-3-.16,22,22,0,0,0-8.47,1.66,20.41,20.41,0,0,0-7,4.75,22.38,22.38,0,0,0-4.63,7.34,26,26,0,0,0-1.66,9.47c0,.76,0,1.64.11,2.6s.21,2,.39,3a28.17,28.17,0,0,0,.65,2.88,12.57,12.57,0,0,0,1.19,2.91l2.26,4m17.15-54.69a38.35,38.35,0,0,0-14.76,2.85,36.42,36.42,0,0,0-20.25,20.42,42.18,42.18,0,0,0-3,16.12,40.8,40.8,0,0,0,3,15.8,38,38,0,0,0,8.16,12.4,37.27,37.27,0,0,0,12.09,8.13,38.71,38.71,0,0,0,23.83,1.84,37.13,37.13,0,0,0,8.4-3.16,38.5,38.5,0,0,0,7.44-5.07,37.5,37.5,0,0,0,6.08-6.74l-13.9-14a18.44,18.44,0,0,1-2.88,4.55A18.24,18.24,0,0,1,407,96a19.16,19.16,0,0,1-4.83,2.19,18.65,18.65,0,0,1-5.35.76c-.74,0-1.48,0-2.22-.1a10.93,10.93,0,0,1-2.22-.45L428,55.53a37.8,37.8,0,0,0-6.11-6.78,38.57,38.57,0,0,0-7.47-5.1,38,38,0,0,0-8.44-3.2,37.36,37.36,0,0,0-9.1-1.11ZM380,88.59a9.72,9.72,0,0,1-.9-2.22c-.23-.83-.43-1.7-.59-2.61s-.28-1.79-.35-2.67-.1-1.67-.1-2.36a22.58,22.58,0,0,1,1.46-8.37,19.11,19.11,0,0,1,4-6.36,17.44,17.44,0,0,1,6-4.06,19,19,0,0,1,7.33-1.43,25.68,25.68,0,0,1,2.68.14,11.06,11.06,0,0,1,2.6.63L380,88.59Z"
    />
    <path
      fill={props.fill}
      d="M505.82,15.6v98h-.05l-6.46-9-1.95-2.71-2.49,2.23a56,56,0,0,1-5.45,4.33,39.47,39.47,0,0,1-5.91,3.42,35.55,35.55,0,0,1-6.31,2.28,26,26,0,0,1-6.37.81,34.43,34.43,0,0,1-24.72-10.26,35.87,35.87,0,0,1-7.53-11.48,39.54,39.54,0,0,1,0-29,36.13,36.13,0,0,1,7.53-11.55,35,35,0,0,1,11.13-7.61,34,34,0,0,1,13.58-2.74,31.2,31.2,0,0,1,4.71.38,23.72,23.72,0,0,1,4.63,1.21,18.53,18.53,0,0,1,4.1,2.12,11.72,11.72,0,0,1,3,3L492.72,57V15.6h13.1m-35,86.34a19.32,19.32,0,0,0,8.66-2,23.52,23.52,0,0,0,6.89-5.18A24.72,24.72,0,0,0,491,87.4a22.74,22.74,0,0,0,0-17.35,24.67,24.67,0,0,0-4.62-7.34,23.35,23.35,0,0,0-6.89-5.18,19.33,19.33,0,0,0-8.66-2,22.46,22.46,0,0,0-8.46,1.61,20.41,20.41,0,0,0-7,4.68,21.93,21.93,0,0,0-4.64,7.37,26.34,26.34,0,0,0-1.66,9.56A24.17,24.17,0,0,0,455.31,95a21.73,21.73,0,0,0,6.92,5.08,20.32,20.32,0,0,0,8.6,1.87m38-89.34h-19.1V47.33A14.53,14.53,0,0,0,486,43.58a21.73,21.73,0,0,0-4.76-2.47A27.52,27.52,0,0,0,476,39.76a33.33,33.33,0,0,0-5.17-.42,36.86,36.86,0,0,0-14.77,3A37.85,37.85,0,0,0,444,50.59a39,39,0,0,0-8.17,12.51,40.55,40.55,0,0,0-3,15.63,40.41,40.41,0,0,0,3,15.66A38.7,38.7,0,0,0,444,106.83,37.51,37.51,0,0,0,470.83,118a28.61,28.61,0,0,0,7.12-.91,39.11,39.11,0,0,0,6.84-2.46,42.28,42.28,0,0,0,6.35-3.68,62.32,62.32,0,0,0,5.74-4.55l7.36,10.21h4.58V12.6Zm-38,86.34a17.09,17.09,0,0,1-7.33-1.6,18.51,18.51,0,0,1-6-4.37,21.2,21.2,0,0,1-5.45-14.24,23.31,23.31,0,0,1,1.46-8.48,18.57,18.57,0,0,1,4-6.35,17.23,17.23,0,0,1,6-4,19.47,19.47,0,0,1,7.33-1.39,16.21,16.21,0,0,1,7.32,1.71,19.91,19.91,0,0,1,6,4.51,21.7,21.7,0,0,1,4.07,6.46,19.82,19.82,0,0,1,0,15.08,21.7,21.7,0,0,1-4.07,6.46,20.07,20.07,0,0,1-6,4.51,16.2,16.2,0,0,1-7.32,1.7Z"
    />
    <polygon
      fill={props.fill}
      points="0.49 14.58 110.51 14.58 55.5 118.2 0.49 14.58"
    />
    <rect fill={props.fill} width="111" height="5" />
  </svg>
);

interface LogoProps {
  variant?: "default" | "mark" | "icon";
  color?: "secondary" | "light";
  width?: number;
}

const Title = styled.div<{ color: LogoProps["color"] }>`
  ${({ theme, ...props }) => `
    display: flex;
    align-items: flex-end;
    justify-content:center;
    line-height: 1;
    font-size: ${theme.typography.pxToRem(28)};
    font-family: ${theme.typography.h1.fontFamily};
    font-weight: ${theme.typography.h1.fontWeight};
    color: ${
      props.color === "light"
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main
    }
  `}
`;

const LogoWrapper = styled.div`
  width: 22px;
  transform: translateX(2px);
`;

export const Logo: React.FC<LogoProps> = (props) => {
  let fill = theme.palette.primary.main;

  if (props.color === "secondary") {
    fill = theme.palette.secondary.main;
  }

  if (props.color === "light") {
    fill = theme.palette.primary.contrastText;
  }

  let Component = LogoFull;

  if (props.variant === "mark") {
    Component = LogoMark;
  }

  if (props.variant === "icon") {
    Component = LogoIcon;
  }

  let width = `${props.width}px` ?? "100%";

  return (
    <React.Fragment>
      <div style={{ width }}>
        {!props.variant ? (
          <Title color={props.color}>
            <LogoWrapper>
              <LogoIcon fill={fill} />
            </LogoWrapper>
            <span aria-label="VerifiedToken">erifiedToken</span>
          </Title>
        ) : (
          <Component fill={fill} />
        )}
      </div>
    </React.Fragment>
  );
};
