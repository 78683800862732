import { useQuery } from "@apollo/react-hooks";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ResponsibilityIcon from "@material-ui/icons/BeenhereOutlined";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "../../components/Button";
import { GridContainer, GridItem } from "../../components/Grid";
import { HorizontalStat } from "../../components/HorizontalStat";
import { Loader } from "../../components/Loader";
import { Paper } from "../../components/Paper";
import { Typography } from "../../components/Typography";
import { CourseList } from "../../containers/CourseList";
import { Layout } from "../../containers/Layout";
import { GetRoleDataQuery, GET_ROLE_DATA } from "../../queries";
import { captureEvent } from "../../utils/firebase";
import { getRoleStats } from "../../utils/getters";
import { RouteOptions } from "../../utils/routes";
import { BackToTop } from "../../components/BackToTop";

interface ScreenProps extends RouteComponentProps<{ role?: string }> {}

export const Role: React.FC<ScreenProps> = (props) => {
  const { loading, error, data } = useQuery<GetRoleDataQuery>(GET_ROLE_DATA, {
    variables: {
      role: props.match.params.role,
    },
  });

  return (
    <Layout>
      {loading || error || !data ? (
        <Loader error={error ? "Something went wrong" : undefined} />
      ) : (
        <React.Fragment>
          <GridContainer>
            <GridItem>
              <Paper>
                <Typography
                  variant="h1"
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {data.role.name}
                </Typography>
              </Paper>
            </GridItem>

            <GridItem>
              <HorizontalStat id="role" items={getRoleStats(data.role)} />
            </GridItem>

            <GridItem>
              <Paper>
                <Typography
                  variant="h2"
                  color="textPrimary"
                  align="center"
                  gutterBottom={true}
                >
                  Responsibilities
                </Typography>
                <Typography
                  align="center"
                  style={{ maxWidth: 600, margin: "0 auto .5rem" }}
                >
                  {data.role.description}
                </Typography>
                <List dense={true} disablePadding={true}>
                  {data.role.responsibilities.map((responsibility, index) => (
                    <React.Fragment key={`responsbility-${index}`}>
                      <ListItem disableGutters={true}>
                        <ListItemIcon style={{ minWidth: 30 }}>
                          <ResponsibilityIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography>{responsibility}</Typography>
                      </ListItem>
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
            </GridItem>

            <GridItem>
              <Typography variant="h2" color="textPrimary" align="center">
                Courses
              </Typography>
            </GridItem>
          </GridContainer>

          <CourseList tokens={data.role.tokens} courses={data.role.courses} />

          <GridContainer>
            <GridItem>
              <Button
                state="inverted"
                color="secondary"
                onClick={async () => {
                  await captureEvent({
                    category: "back",
                    value: `${data.role.id}`,
                  });
                  props.history.push(RouteOptions.Roles);
                }}
              >
                browse roles
              </Button>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      )}
      <BackToTop />
    </Layout>
  );
};
