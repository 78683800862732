import React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import { Root } from "./style";
import { Paper } from "../Paper";
import { Typography } from "../Typography";

export interface LoaderProps {
  error?: string;
  color?: CircularProgressProps["color"] | "light";
}

export const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <React.Fragment>
      {props.error ? (
        <Paper>
          <Typography variant="h3" color="error" component="p" align="center">
            {props.error}
          </Typography>
        </Paper>
      ) : (
        <Root color={props.color}>
          <CircularProgress color="inherit" />
        </Root>
      )}
    </React.Fragment>
  );
};
