import React from "react";
import { InfoBlock } from "../../components/InfoBlock";
import { Typography } from "../../components/Typography";
import { Emoji } from "../../components/Emoji";

interface FilterErrorProps {
  name: string;
}

export const FilterError: React.FC<FilterErrorProps> = (props) => {
  return (
    <InfoBlock variant="error">
      <Typography variant="body2" component="p" display="block">
        <Typography
          variant="overline"
          component="span"
          display="block"
          paragraph={true}
        >
          No {props.name} found
          {/* We're unable to find any {props.name} matching your search criteria */}
          <Emoji label="fire" value="🔥" />
        </Typography>
        <Typography color="textPrimary" component="span">
          Try expanding your search
        </Typography>
      </Typography>
    </InfoBlock>
  );
};
