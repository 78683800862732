import styled, { css } from "styled-components";
import { Button } from "../../Button";

const SelectedButtonMixin = css`
  ${({ theme }) => `
    background:${theme.palette.primary.main};
    border-color: ${theme.palette.secondary.main};
    color:${theme.palette.primary.contrastText};
    &:hover {
      background: ${theme.palette.primary.main};
    }
  `}
`;

export const AnswerButton = styled(Button)<{ selected: boolean }>`
  ${({ theme }) => `
    min-height: 150px;
    height: 100%;
    width: 100%;
    padding: ${theme.spacing(2, 0)};
    border: 2px solid ${theme.palette.primary.light};
    text-align: left;
    margin: 0;
    background:${theme.palette.primary.light};
    color:${theme.palette.primary.contrastText};
    &:hover {
      background: ${theme.palette.primary.dark};
    }
  `}

  ${({ ...props }) => props.selected && SelectedButtonMixin}
`;

const NormalizeCodeMixin = css`
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(14)};
    font-family: ${theme.typography.body1.fontFamily};
    white-space: break-spaces;
    text-align: center;

  `}
`;

export const AnswerBlock = styled.pre`
  ${({ theme }) => `
font-size: ${theme.typography.pxToRem(14)};
font-family: ${theme.typography.body1.fontFamily};
white-space: break-spaces;
text-align: center;

`}
`;

export const CodeBlock = styled.pre<{ isCode?: boolean }>`
  ${({ theme }) => `
    color: inherit;
    overflow: auto;
    // max-height: 120px;
    margin: 0;
    font-size: ${theme.typography.pxToRem(16)};
    padding: ${theme.spacing(0, 1)};
    font-weight: normal;
  `}
  ${({ theme, ...props }) => !props.isCode && NormalizeCodeMixin}
`;
