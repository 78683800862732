import React from "react";
import { Pill, SelectIcon, DeleteIcon } from "./style";

interface SelectPillprops {
  label: string;
  checked: boolean;
  disabled?: boolean;
  onClick: () => void;
  onRemove: () => void;
}

export const SelectPill: React.FC<SelectPillprops> = (props) => {
  return (
    <Pill
      label={props.label}
      onClick={props.onClick}
      onDelete={props.onRemove}
      deleteIcon={props.checked ? <DeleteIcon /> : <SelectIcon />}
      selected={props.checked}
      disabled={props.disabled}
    />
  );
};
