import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import React from "react";

import {
  Root,
  Header,
  FilterContainer,
  FilterLabel,
  FilterList,
  FilterListItem,
} from "./style";
import {
  SortOrderTypes,
  SelectSortList,
  SelectSort,
} from "../../components/Select/SelectSort";
import { TokenFilterProps } from "../../screens/Tokens";
import { ButtonIcon } from "../../components/ButtonIcon";
import { SearchField } from "../../components/Search";
import { Icon } from "../../components/Icon";
import { Button } from "../../components/Button";
import { captureEvent } from "../../utils/firebase";
import { GridContainer, GridItem } from "../../components/Grid";
import { SelectPill } from "../../components/Select/SelectPill";

interface SelectOptions {
  value: string;
  label: string;
  selected: boolean;
}

export interface TokenFilterSortProps {
  type: "level" | "rating";
  order: SortOrderTypes;
}

interface FitlerBarProps {
  roles: SelectOptions[];
  onReset: () => void;
  filters: TokenFilterProps;
  onSort: (values: TokenFilterSortProps) => void;
  onSearch: (term: string) => void;
  onFilter: (value: string) => void;
}

export const FilterTokens: React.FC<FitlerBarProps> = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <Root position="sticky" component="section" elevation={1} square={true}>
      <ExpansionPanel square={true} elevation={0} expanded={expanded}>
        <Header>
          <ButtonIcon
            size="lg"
            edge={false}
            onClick={() => setExpanded(!expanded)}
          >
            <Icon selected={expanded} variant="filter" />
          </ButtonIcon>
          <SearchField
            label="Search tokens"
            onSubmit={async (value) => {
              await captureEvent({
                category: "filter",
                value: `search-[${value}]`,
              });
              props.onSearch(value.toLowerCase());
            }}
          />
        </Header>
        <FilterContainer>
          <GridContainer>
            <GridItem>
              <SelectSortList>
                <SelectSort
                  component="li"
                  label="Popularity"
                  order={props.filters.rating ?? undefined}
                  onClick={async (order) => {
                    await captureEvent({
                      category: "filter",
                      value: `pop-${order}`,
                    });
                    props.onSort({
                      type: "rating",
                      order,
                    });
                  }}
                />
                <SelectSort
                  component="li"
                  label="Difficulty "
                  order={props.filters.level ?? undefined}
                  onClick={async (order) => {
                    await captureEvent({
                      category: "filter",
                      value: `dif-${order}`,
                    });

                    props.onSort({
                      type: "level",
                      order,
                    });
                  }}
                />
              </SelectSortList>

              <FilterLabel variant="overline">Filter by speciality</FilterLabel>
              <FilterList>
                {props.roles.map((role) => (
                  <FilterListItem key={role.value}>
                    <SelectPill
                      checked={role.selected}
                      onClick={() => props.onFilter(role.value)}
                      onRemove={() => props.onFilter(role.value)}
                      label={`${role.label}`}
                    />
                  </FilterListItem>
                ))}
              </FilterList>
            </GridItem>
            <GridItem>
              <Button
                // @TODO disable button when filter is not dirty
                color="secondary"
                onClick={props.onReset}
              >
                clear filters
              </Button>
            </GridItem>
          </GridContainer>
        </FilterContainer>
      </ExpansionPanel>
    </Root>
  );
};
