import ButtonComponent, { ButtonProps } from "@material-ui/core/Button";
import styled, { css } from "styled-components";

interface ButtonIconStyleProps extends ButtonProps {
  state: "default" | "inverted" | "disabled";
  vcolor?: "primary" | "secondary" | "error";
}

const SmallMixin = css`
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(12)};
    padding: ${theme.spacing(0.2, 1)};
    width: auto;
  `}
`;

export const ErrorButtonMixin = css`
  ${({ theme }) => `
    background: ${theme.palette.error.main};
    color: ${theme.palette.error.contrastText};

    &:hover {
      background: ${theme.palette.error.dark};
      color: ${theme.palette.error.contrastText};
    }
  `}
`;

export const OutlinedButtonMixin = css<{
  vcolor?: "primary" | "secondary" | "error";
}>`
  ${({ theme, ...props }) => `
    background: transparent;
    border: 2px solid ${theme.palette[props.vcolor ?? "primary"].main};
    color: ${theme.palette[props.vcolor ?? "primary"].main};

    &:hover {
      color: ${theme.palette[props.vcolor ?? "primary"].contrastText};
      background: ${theme.palette[props.vcolor ?? "primary"].main};
    }
  `}
`;

export const DisabledButtonMixin = css`
  ${({ theme }) =>
    `
    && {
      background: ${theme.palette.common.black};
      border-color: ${theme.palette.common.black};
      color:${theme.palette.text.disabled};
      opacity: .5;
    }
  `}
`;

export const Root = styled(ButtonComponent)<ButtonIconStyleProps>`
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(14)};
    border: 2px solid transparent;
    padding: ${theme.spacing(0.5, 1)};
    font-weight: bold;
    text-transform: none;
  `}
  ${({ ...props }) => props.size === "small" && SmallMixin}

  ${({ ...props }) => props.vcolor === "error" && ErrorButtonMixin}

  ${({ ...props }) => props.state === "inverted" && OutlinedButtonMixin}
  ${({ ...props }) => props.state === "disabled" && DisabledButtonMixin}
`;
