import React from "react";
import { Root, Text } from "./style";

interface InfoBlockProps {
  children?: React.ReactNode;
  text?: string;
  variant?: "secondary" | "error";
}

export const InfoBlock: React.FC<InfoBlockProps> = (props) => {
  return (
    <Root state={props.variant ?? "primary"}>
      {props.text ? (
        <Text
          align="center"
          variant="overline"
          display="inline"
          color="textPrimary"
        >
          {props.text}
        </Text>
      ) : (
        props.children
      )}
    </Root>
  );
};
