import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import styled, { css } from "styled-components";

const CheckedMixin = css`
  ${({ theme }) =>
    `
    && {
      color: ${theme.palette.secondary.main};
    }
`}
`;

const RequiredMixin = css`
  ${({ theme }) =>
    `
    && {
      color: ${theme.palette.error.main};
    }
`}
`;

const DisabledLabelMixin = css`
  ${({ theme }) =>
    `
    && {
      color: ${theme.palette.text.disabled};
      cursor: default;
    }
`}
`;

export const Check = styled(Checkbox)<{
  required?: boolean;
}>`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
    padding: ${theme.spacing(0.5)}px;
    border-radius: 0;
  `}

  ${({ theme, ...props }) => props.checked && CheckedMixin}
  ${({ theme, ...props }) => props.required && RequiredMixin}
`;

export const Label = styled(FormLabel)<{
  disabled: boolean;
  required?: boolean;
}>`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
    font-size: ${theme.typography.pxToRem(14)};
    cursor: pointer;
    transition: all .5s ease;
  `}

  ${({ theme, ...props }) => props.required && RequiredMixin}
  ${({ theme, ...props }) => props.disabled && DisabledLabelMixin}
`;
