import React from "react";
import Grid, { GridProps } from "@material-ui/core/Grid";

export const GridContainer: React.FC<GridProps> = (props) => (
  <Grid container={true} spacing={2} {...props}>
    {props.children}
  </Grid>
);

export const GridItem: React.FC<GridProps> = (props) => (
  <Grid item={true} xs={12} {...props}>
    {props.children}
  </Grid>
);
