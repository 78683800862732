import React from "react";
import { Root } from "./style";

interface LinkProps {
  href?: string;
  onClick?: () => void;
  noDecoration?: boolean;
  newTab?: boolean;
}

export const Link: React.FC<LinkProps> = (props) => {
  if (!props.href && !props.onClick) {
    console.error("Must define href or onclick prop");
  }

  return (
    <Root
      disabled={!props.href && !props.onClick ? true : false}
      clean={props.noDecoration ?? false}
      underline="none"
      href={props.href}
      onClick={props.onClick}
      target={props.newTab ? "_blank" : ""}
    >
      {props.children}
    </Root>
  );
};
