import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "../../components/Button";
import { Emoji } from "../../components/Emoji";
import { GridContainer, GridItem } from "../../components/Grid";
import { Loader } from "../../components/Loader";
import { Paper } from "../../components/Paper";
import { SortOrderTypes } from "../../components/Select/SelectSort";
import { Typography } from "../../components/Typography";
import { CardToken } from "../../containers/CardToken";
import {
  FilterTokens,
  TokenFilterSortProps,
} from "../../containers/FilterBars/FilterTokens";
import { Layout } from "../../containers/Layout";
import { GetTokensQuery, GET_TOKENS_DATA } from "../../queries";
import { RouteOptions } from "../../utils/routes";
import { FilterError } from "../../containers/FilterBars/FilterError";

export interface TokenFilterProps {
  level: SortOrderTypes | null;
  rating: SortOrderTypes | null;
}

export const ViewToken: React.FC<RouteComponentProps> = (props) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { loading, error, data } = useQuery<GetTokensQuery>(GET_TOKENS_DATA);
  const [tokens, setTokens] = React.useState<GetTokensQuery["tokens"] | null>(
    null
  );
  const [filters, setFilters] = React.useState<TokenFilterProps>({
    level: null,
    rating: null,
  });
  const [roles, setRoles] = React.useState<Array<{
    label: string;
    value: string;
    selected: boolean;
  }> | null>(null);

  const filteredRoles =
    roles?.filter((role) => role.selected).map((role) => role.value) ?? [];

  React.useEffect(() => {
    if (tokens === null && data && data.tokens) {
      setTokens(data.tokens);
    }
  }, [tokens, data]);

  React.useEffect(() => {
    if (roles === null && data && data.roles.length > 0) {
      setRoles(
        data.roles.map((role) => ({
          label: role.verifiedName,
          value: role.identifier,
          selected: false,
        }))
      );
    }
  }, [roles, data]);

  const onFilter = (value: string) => {
    const updatedRoles =
      roles?.map((role) => {
        if (role.value === value) {
          return {
            ...role,
            selected: !role.selected,
          };
        }

        return role;
      }) ?? null;
    setRoles(updatedRoles);
  };

  // @TODO clean up DRY style
  const onSort = (values: TokenFilterSortProps) => {
    const updatedFilters = {
      ...filters,
      [values.type]: values.order,
    };

    setFilters(updatedFilters);

    if (tokens !== null) {
      let formattedTokens = [...tokens];

      const filteredByLevel = updatedFilters.level !== null;
      const filteredByRating = updatedFilters.rating !== null;

      if (filteredByRating && filteredByLevel) {
        formattedTokens.sort((a, b) => {
          const ratingOrder =
            updatedFilters.rating === "asc"
              ? a.rating - b.rating
              : b.rating - a.rating;

          if (a.rating === b.rating && a.complexity !== b.complexity) {
            return updatedFilters.level === "asc"
              ? a.complexity - b.complexity
              : b.complexity - a.complexity;
          }
          return ratingOrder;
        });
      } else {
        if (filteredByRating) {
          formattedTokens.sort((a, b) => {
            return updatedFilters.rating === "asc"
              ? a.rating - b.rating
              : b.rating - a.rating;
          });
        }

        if (filteredByLevel) {
          formattedTokens.sort((a, b) => {
            return updatedFilters.level === "asc"
              ? a.complexity - b.complexity
              : b.complexity - a.complexity;
          });
        }
      }

      setTokens(formattedTokens);
    }
  };

  const filteredTokens = (tokens ?? []).filter(
    (token) =>
      (filteredRoles.length <= 0 ||
        token.roles.find((role) => filteredRoles.includes(role.identifier))) &&
      (searchTerm === "" ||
        token.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <Layout>
      <GridContainer>
        <GridItem>
          <Paper>
            <Typography variant="h1" align="center">
              Explore Tokens
            </Typography>
          </Paper>
        </GridItem>
        <GridItem>
          <Paper>
            <Typography align="center" paragraph={true}>
              Ready to learn a new tech skill?
              {/* Welcome to the right place */}
            </Typography>
            <Typography align="center" paragraph={true}>
              Explore the tokens that unlock a sustainable career{" "}
              <Emoji label="hundred points symbol" value="💯" />
            </Typography>
            <Typography variant="body2" align="center" display="block">
              <strong>#LearnToCode</strong>
            </Typography>
          </Paper>
        </GridItem>

        <GridItem>
          {loading || error || tokens === null ? (
            <Loader error={error ? "Something went wrong" : undefined} />
          ) : (
            <GridContainer>
              <GridItem>
                <FilterTokens
                  roles={roles ?? []}
                  filters={filters}
                  onSort={onSort}
                  onSearch={setSearchTerm}
                  onFilter={onFilter}
                  onReset={() => {
                    setFilters({
                      level: null,
                      rating: null,
                    });

                    setRoles(null);

                    setTokens(data?.tokens ?? null);
                  }}
                />
              </GridItem>

              {filteredTokens.length <= 0 ? (
                <GridItem>
                  <FilterError name="tokens" />
                </GridItem>
              ) : (
                <React.Fragment>
                  {filteredTokens.map((token) => (
                    <React.Fragment key={token.id}>
                      <GridItem md={6}>
                        <CardToken token={token} />
                      </GridItem>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
            </GridContainer>
          )}
        </GridItem>
        <GridItem>
          <Button
            state="inverted"
            color="secondary"
            onClick={() => props.history.push(RouteOptions.Courses)}
          >
            browse courses
          </Button>
        </GridItem>
      </GridContainer>
    </Layout>
  );
};
