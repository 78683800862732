import {
  FastField,
  FastFieldProps,
  Field,
  FieldProps,
  Form,
  Formik,
  useFormikContext,
} from "formik";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { Button } from "../../components/Button";
import { GridContainer, GridItem } from "../../components/Grid";
import { PageWrapper } from "../../components/PageWrapper";
import { Paper } from "../../components/Paper";
import { TextArea } from "../../components/TextArea";
import { TextField } from "../../components/TextField";
import { Typography } from "../../components/Typography";
import { Layout } from "../../containers/Layout";
import { sendEmail } from "../../utils/firebase";
import { RouteOptions } from "../../utils/routes";
import { Checkbox } from "../../components/Checkbox";
import { homeButtonLabel } from "../../utils/getters";

const TermsLink = styled.a`
  ${({ theme }) => `
    font-size:${theme.typography.pxToRem(10)};
    color: ${theme.palette.error.main};
  `}
`;

const TermsCheckbox = styled(Checkbox)`
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(10)};
    color: ${theme.palette.error.main};
  `}
`;

const Error = styled(Paper)`
  ${({ theme }) => `
    background: ${theme.palette.error.main};
    color: ${theme.palette.error.contrastText};
    padding: ${theme.spacing(1)}px;
    margin-bottom: ${theme.spacing(1)}px;
  `}
`;

interface FormValues {
  name: string;
  email: string;
  message?: string;
}

interface ScreenProps extends RouteComponentProps {}

const FormResetter: React.FC<{ path: string }> = (props) => {
  const { resetForm } = useFormikContext();

  React.useEffect(() => {
    resetForm();
  }, [props.path, resetForm]);

  return null;
};

export const ContactForm: React.FC<ScreenProps> = (props) => {
  const requestingRole = props.match.path.includes(RouteOptions.RequestRole);
  const [needsAgreeing, setsNeedsAgreeing] = React.useState(false);
  const [hasAgreed, setHasAgreed] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const onSubmit = async (values: FormValues) => {
    setError(null);
    if (!hasAgreed) {
      return setsNeedsAgreeing(true);
    }
    setIsSubmitting(true);
    const payload = {
      ...values,
      message: values.message ?? "Unknown Message",
    };

    try {
      await sendEmail(payload);
      setHasSubmitted(true);
    } catch (error) {
      console.error(error);
      setError("Something went wrong");
    }
    setIsSubmitting(false);
  };

  const getTitle = () => {
    if (hasSubmitted) {
      return "Thanks!";
    }

    if (requestingRole) {
      return "Role Suggestion";
    }

    return "Get in touch";
  };

  const onReturn = () => props.history.push(RouteOptions.Home);

  return (
    <Layout>
      <PageWrapper>
        <Typography variant="h1" gutterBottom={true} align="center">
          {getTitle()}
        </Typography>

        {!hasSubmitted && (
          <React.Fragment>
            {requestingRole && (
              <Typography variant="body1" paragraph={true} align="center">
                There are lots of tech roles out there, we get that, but think
                we missed an obvious one?
                <br /> We're eager to hear all about it
              </Typography>
            )}
            {!requestingRole && (
              <Typography variant="body1" paragraph={true} align="center">
                We're always happy to read about your wishes, hopes and dreams!{" "}
                <br />
                Other messages are somewhat welcome too
              </Typography>
            )}
          </React.Fragment>
        )}

        {error && (
          <Error>
            <Typography color="inherit" component="p" align="center">
              {error}{" "}
              <span role="img" aria-label="face screaming in fear">
                😱
              </span>
            </Typography>
          </Error>
        )}

        {hasSubmitted ? (
          <GridContainer>
            <GridItem>
              <Typography variant="body1" align="center">
                Looking forward to finding out what needs our attention
              </Typography>
            </GridItem>
            <GridItem>
              <Button onClick={onReturn}>{homeButtonLabel}</Button>
            </GridItem>
          </GridContainer>
        ) : (
          <Formik
            onSubmit={onSubmit}
            validateOnBlur={false}
            initialValues={{ name: "", email: "", message: "" }}
            validationSchema={Yup.object().shape({
              message: Yup.string().required(
                `${requestingRole ? "role" : "message"} can't be empty`
              ),
              email: Yup.string()
                .email("Invalid email")
                .required("Email can't be empty"),
            })}
          >
            {(formProps) => (
              <Form noValidate={true}>
                <FormResetter path={props.match.path} />
                <GridContainer>
                  <GridItem sm={6}>
                    <FastField name="name">
                      {({ field }: FastFieldProps<FormValues>) => (
                        <TextField
                          {...field}
                          label="name"
                          error={
                            formProps.touched.name
                              ? formProps.errors.name
                              : undefined
                          }
                        />
                      )}
                    </FastField>
                  </GridItem>
                  <GridItem sm={6}>
                    <FastField name="email">
                      {({ field }: FastFieldProps<FormValues>) => (
                        <TextField
                          {...field}
                          label="email"
                          type="email"
                          required={true}
                          error={
                            formProps.touched.email
                              ? formProps.errors.email
                              : undefined
                          }
                        />
                      )}
                    </FastField>
                  </GridItem>

                  <GridItem>
                    <Field name="message">
                      {({ field }: FieldProps<FormValues>) => (
                        <TextArea
                          {...field}
                          required={true}
                          label={requestingRole ? "role" : "message"}
                          placeholder={
                            requestingRole
                              ? "Let me tell you about this tech role you somehow missed..."
                              : "I was wondering..."
                          }
                          error={
                            formProps.touched.message
                              ? formProps.errors.message
                              : undefined
                          }
                        />
                      )}
                    </Field>
                  </GridItem>

                  <GridItem>
                    <TermsCheckbox
                      name="terms"
                      label="I agree with the handling of my data in accordance with VerifiedToken's privacy policy"
                      onClick={() => setHasAgreed(!hasAgreed)}
                      required={needsAgreeing}
                      checked={hasAgreed}
                    />

                    <TermsLink href={RouteOptions.Privacy} target="_blank">
                      *Read the legal jargon you're agreeing to
                    </TermsLink>
                  </GridItem>
                  <GridItem>
                    <Button
                      type="submit"
                      onClick={formProps.submitForm}
                      state={isSubmitting ? "disabled" : undefined}
                    >
                      send{isSubmitting ? "ing.." : ""}
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button color="secondary" onClick={onReturn}>
                      {homeButtonLabel}
                    </Button>
                  </GridItem>
                </GridContainer>
              </Form>
            )}
          </Formik>
        )}
      </PageWrapper>
    </Layout>
  );
};
