import InputLabel from "@material-ui/core/InputLabel";
import styled, { css } from "styled-components";
import { Typography } from "../Typography";

const ErrorMixin = css`
  && {
    ${({ theme }) => `
      background: ${theme.palette.error.main};
      color: ${theme.palette.error.contrastText};
      font-weight: bold;
    `}
  }
`;

export const Root = styled(InputLabel)<{ error: boolean }>`
  ${({ theme }) => `
    && {
      color: ${theme.palette.secondary.main};
      font-size: ${theme.typography.pxToRem(14)};
      font-weight: bold;
      padding: ${theme.spacing(1, 0.5)};
      transition: all .5s ease;
      transform: translate(0, -12px);
      width: 100%;
    }
  `}
  ${({ ...props }) => props.error && ErrorMixin}
`;

export const RequiredText = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.error.main};
    font-size: ${theme.typography.pxToRem(12)};
    font-weight: inherit;
  `}
`;
