export function formatNumber(value: number) {
  return `${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function formatRating(value: number) {
  // easy, normal, hard,
  // novice, expert.

  if (value < 2) {
    return "Low";
  }

  if (value <= 3) {
    return "Moderate";
  }

  return "High";
}

export function formatDifficulty(value: number) {
  // easy, normal, hard,
  // novice, expert.

  if (value < 2) {
    return "Easy";
  }

  if (value <= 3) {
    return "Standard";
  }

  if (value <= 4) {
    return "Hard";
  }

  return "Intense";
}
