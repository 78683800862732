import React from "react";
import { HygienicPage } from ".";
import { Link } from "../../components/Link";
import { Typography } from "../../components/Typography";
import { companyName } from "../../utils/getters";
import { RouteOptions } from "../../utils/routes";

export const Terms: React.FC = () => {
  return (
    <HygienicPage title="Terms &amp; Conditions">
      <Typography paragraph={true}>
        By accessing the website at{" "}
        <Link href={RouteOptions.Home}>https://verifiedtoken.com</Link>, you are
        agreeing to be bound by these terms of service, all applicable laws and
        regulations, and agree that you are responsible for compliance with any
        applicable local laws. If you do not agree with any of these terms, you
        are prohibited from using or accessing this site. The materials
        contained in this website are protected by applicable copyright and
        trademark law.
      </Typography>

      <Typography paragraph={true}>
        The materials on {companyName}'s website are provided on an 'as is'
        basis. {companyName} makes no warranties, expressed or implied, and
        hereby disclaims and negates all other warranties including, without
        limitation, implied warranties or conditions of merchantability, fitness
        for a particular purpose, or non-infringement of intellectual property
        or other violation of rights. Further, {companyName} does not warrant or
        make any representations concerning the accuracy, likely results, or
        reliability of the use of the materials on its website or otherwise
        relating to such materials or on any sites linked to this site.
      </Typography>

      <Typography paragraph={true}>
        In no event shall {companyName} or its suppliers be liable for any
        damages (including, without limitation, damages for loss of data or
        profit, or due to business interruption) arising out of the use or
        inability to use the materials on {companyName}'s website, even if{" "}
        {companyName} or a {companyName} authorized representative has been
        notified orally or in writing of the possibility of such damage. Because
        some jurisdictions do not allow limitations on implied warranties, or
        limitations of liability for consequential or incidental damages, these
        limitations may not apply to you.
      </Typography>

      <Typography paragraph={true}>
        The materials appearing on {companyName}'s website could include
        technical, typographical, or photographic errors. {companyName} does not
        warrant that any of the materials on its website are accurate, complete
        or current. {companyName} may make changes to the materials contained on
        its website at any time without notice. However {companyName} does not
        make any commitment to update the materials.
      </Typography>

      <Typography paragraph={true}>
        {companyName} has not reviewed all of the sites linked to its website
        and is not responsible for the contents of any such linked site. The
        inclusion of any link does not imply endorsement by {companyName} of the
        site. Use of any such linked website is at the user's own risk.
      </Typography>

      <Typography paragraph={true}>
        {companyName} may revise these terms of service for its website at any
        time without notice. By using this website you are agreeing to be bound
        by the then current version of these terms of service.
      </Typography>

      <Typography paragraph={true}>
        These terms and conditions are governed by and construed in accordance
        with the laws of the United Kingdom and you irrevocably submit to the
        exclusive jurisdiction of the courts in that State or location.
      </Typography>
    </HygienicPage>
  );
};
