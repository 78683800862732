import React from "react";
import { Root, RequiredText } from "./style";

interface LabelProps {
  name: string;
  label: string;
  required?: boolean;
  error?: string;
}

export const Label: React.FC<LabelProps> = (props) => {
  return (
    <Root
      error={Boolean(props.error)}
      id={props.name}
      htmlFor={props.name}
      shrink={true}
    >
      <span>{props.error || props.label}</span>
      <RequiredText component="span">{props.required ? " *" : ""}</RequiredText>
    </Root>
  );
};
