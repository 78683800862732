import Duration from "@material-ui/icons/AccessTime";
import Author from "@material-ui/icons/ApartmentOutlined";
import Level from "@material-ui/icons/EqualizerOutlined";
import styled, { css } from "styled-components";
import { Paper } from "../Paper";
import { Typography } from "../Typography";
import { ButtonIcon } from "../ButtonIcon";
import { Link } from "../Link";

export const Root = styled(Paper)`
  ${({ theme }) => `
    display: flex;
    height: 100%;
    padding: ${theme.spacing(2)}px;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    ${theme.breakpoints.up("md")} {
      min-height: 43px;
    }
  `}
`;

export const BookmarkButton = styled(ButtonIcon)`
  ${({ theme }) => `
    position: absolute;
    right: 0;
    top: -20px;
    background: transparent;
    font-size: ${theme.typography.pxToRem(26)};
  `}
`;

export const Title = styled(Typography)`
  flex: 1;
  font-weight: bold;
`;

export const ViewButton = styled(ButtonIcon)`
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    margin-top: ${theme.spacing(1)}px;
  `}
`;

export const Body = styled(Typography)`
  ${({ theme }) => `
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    padding: ${theme.spacing(1, 0)};
    ${theme.breakpoints.up("md")} {
      min-height: 77px;
    };
  `}
`;

export const Footer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const StatsRoot = styled.div`
  ${({ theme }) => `
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: ${theme.spacing(0.5)}px;
    padding-left: ${theme.spacing(0.5)}px;
    ${theme.breakpoints.up("sm")} {
      flex-direction: row;
      margin: 0;
     }
  `}
`;

export const Stats = styled.div`
  ${({ theme }) => `
    align-items: center;
    display: flex;
    flex: 50%;
    margin-top: ${theme.spacing(1)}px;
  `}
`;

const TextMixin = css`
  ${({ theme }) => `
    font-size: ${theme.typography.body2.fontSize};
    font-family: ${theme.typography.body1.fontFamily};
    text-transform: capitalize;
  `}
`;

export const Text = styled(Typography)`
  ${TextMixin}
`;

export const ProviderLink = styled(Link)`
  ${TextMixin}
  ${({ theme }) => `
    cursor: pointer;
    color: ${theme.palette.secondary.dark};
  `}
`;

const IconMixion = css`
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(15)};
    color: ${theme.palette.text.disabled};
    align-self: center;
    transform: translateX(-3px);
    margin-right: ${theme.spacing(0.5)}px;
  `}
`;

export const MoneyIcon = styled(Typography)`
  ${IconMixion}
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(13)};
    font-weight: bold;
    width: 14px;
    text-align: center;
    line-height: 1;
  `}
`;

export const DurationIcon = styled(Duration)`
  ${IconMixion}
`;

export const LevelIcon = styled(Level)`
  ${IconMixion}
`;

export const AuthorIcon = styled(Author)`
  ${IconMixion}
`;
