import InfoIcon from "@material-ui/icons/LiveHelpOutlined";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "../components/Button";
import { GridContainer, GridItem } from "../components/Grid";
import { RouteOptions } from "../utils/routes";
import { Link } from "../components/Link";
import { Paper } from "../components/Paper";

interface NoMatchProps {
  title?: string;
  subtitle?: string;
}

export const NoMatch: React.FC<RouteComponentProps & NoMatchProps> = (
  props
) => {
  const makeContent = () => {
    if (props.subtitle) {
      return props.subtitle;
    }

    if (props.title && !props.subtitle) {
      return "The classic 404 page";
    }

    if (!props.title && !props.subtitle) {
      return "This isn't the page you're looking for...";
    }
  };

  return (
    <div
      style={{
        maxWidth: 650,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        margin: "0 auto",
        minHeight: "100vh",
      }}
    >
      <GridContainer>
        <GridItem>
          <Typography align="center" variant="h1" gutterBottom={true}>
            <React.Fragment>
              <span role="img" aria-label="police car's light emoji">
                🚨{" "}
              </span>
              {props.title ?? "The classic 404"}
              <span role="img" aria-label="police car's light emoji">
                {" "}
                🚨
              </span>
            </React.Fragment>
          </Typography>
        </GridItem>
        <GridItem>
          <Typography
            align="center"
            variant="subtitle1"
            component="p"
            paragraph={true}
          >
            {makeContent()}
          </Typography>
        </GridItem>
        <GridItem>
          <Paper>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <InfoIcon color="primary" style={{ marginRight: ".5rem" }} />

              <Typography align="center">
                Let the learning carry on and{" "}
                <Link
                  newTab={true}
                  href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/404"
                >
                  read
                </Link>{" "}
                about the infamous 404 and other{" "}
                <Link
                  newTab={true}
                  href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Status"
                >
                  HTTP
                </Link>{" "}
                status codes!
              </Typography>
            </div>
          </Paper>
        </GridItem>
        <GridItem>
          <Button onClick={() => props.history.push(RouteOptions.Home)}>
            home
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};
