import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from "react";
import { Root, Button } from "./style";

interface BackToTopProps {}

export const BackToTop: React.FC<BackToTopProps> = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  React.useEffect(() => {
    const onScoll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", onScoll);
    return () => window.removeEventListener("scroll", onScoll);
  }, []);

  return (
    <Root in={scrollPosition > 2000}>
      <div>
        <Button
          Icon={UpIcon}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
      </div>
    </Root>
  );
};
