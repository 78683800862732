import ViewIcon from "@material-ui/icons/KeyboardTab";
import BookmarkIcon from "@material-ui/icons/TurnedInNotOutlined";
import React from "react";
import { config } from "../../utils/config";
import { getDuration } from "../../utils/date";
import { Line } from "../Line";
import { Link } from "../Link";
import {
  AuthorIcon,
  Body,
  BookmarkButton,
  DurationIcon,
  Footer,
  Header,
  LevelIcon,
  MoneyIcon,
  ProviderLink,
  Root,
  Stats,
  StatsRoot,
  Text,
  Title,
  ViewButton,
} from "./style";

interface CardProps {
  onClick: () => void;
  title: string;
  body: string;
  stats?: {
    duration?: number;
    price?: string;
    level?: string;
    provider: {
      name: string;
      url: string;
    };
  };
}

function formatBody(text: string) {
  const maxWords = 140;

  if (text.length > maxWords) {
    return `${text.slice(0, maxWords)}...`;
  }

  return text;
}

export const Card: React.FC<CardProps> = (props) => {
  const enableBookmark = ["local"].includes(config.env);
  return (
    <Root>
      <Header>
        {enableBookmark && (
          <BookmarkButton
            Icon={BookmarkIcon}
            onClick={() => console.warn("sign up")}
          />
        )}
        <Title variant="body1">
          <Link onClick={props.onClick}>{props.title}</Link>
        </Title>
      </Header>
      <Body variant="body2" component="p">
        {formatBody(props.body)}
      </Body>

      <Line />
      {props.stats && (
        <Footer>
          <StatsRoot>
            {props.stats.price !== undefined && (
              <Stats>
                <MoneyIcon variant="body2">£</MoneyIcon>

                <Text variant="overline" component="span">
                  {props.stats.price}
                </Text>
              </Stats>
            )}

            {props.stats.duration !== undefined && (
              <Stats>
                <DurationIcon />
                <Text component="span" style={{ textTransform: "none" }}>
                  {props.stats.duration <= 0
                    ? "Months"
                    : `${getDuration(props.stats.duration)}`}
                </Text>
              </Stats>
            )}

            <Stats>
              <LevelIcon />
              <Text component="span">{props.stats.level ?? "-"}</Text>
            </Stats>

            <Stats>
              <AuthorIcon />
              <ProviderLink href={props.stats.provider.url} newTab={true}>
                {props.stats.provider.name}
              </ProviderLink>
            </Stats>
          </StatsRoot>
          <ViewButton
            Icon={ViewIcon}
            size="lg"
            onClick={props.onClick}
            aria-label={`view ${props.stats?.provider.name} course`}
          />
        </Footer>
      )}
    </Root>
  );
};
