import React from "react";
import { Root } from "./style";

interface InputWrapperProps {
  children: React.ReactNode;
}

export const InputWrapper: React.FC<InputWrapperProps> = (props) => {
  return <Root fullWidth={true}>{props.children}</Root>;
};
