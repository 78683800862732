import SearchIcon from "@material-ui/icons/SearchOutlined";
import { FastField, FastFieldProps, Formik } from "formik";
import React from "react";
import { SearchButton, Form, Root, TextField } from "./style";

interface FromValues {
  term: string;
}
interface FitlerBarProps {
  label?: string;
  onSubmit: (term: string) => void;
}

export const SearchField: React.FC<FitlerBarProps> = (props) => {
  return (
    <Formik
      initialValues={{ term: "" }}
      onSubmit={(values) => props.onSubmit(values.term.toLowerCase())}
    >
      {(formProps) => (
        <Form>
          <FastField name="term">
            {({ field }: FastFieldProps<FromValues>) => (
              <Root>
                <TextField
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    props.onSubmit(e.target.value.toLowerCase());
                  }}
                  placeholder={props.label ?? "Search"}
                  id="search"
                />

                <SearchButton
                  aria-label="submit search"
                  onClick={() => {
                    formProps.submitForm();
                  }}
                >
                  <SearchIcon fontSize="inherit" color="inherit" />
                </SearchButton>
              </Root>
            )}
          </FastField>
        </Form>
      )}
    </Formik>
  );
};
