import styled, { css } from "styled-components";
import { Avatar } from "../../components/Avatar";
import { Button } from "../../components/Button";
import { Typography } from "../../components/Typography";

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const CardTitle = styled(Typography)`
  ${({ theme }) => `
    flex: 1;
    margin-left: ${theme.spacing(1)}px;
  `}
`;

export const BadgeBar = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    margin-top: ${theme.spacing(0.5)}px;
    margin-left: -${theme.spacing(0.5)}px;

  `}
`;

export const TokenImage = styled(Avatar)`
  ${({ theme }) => `
    width: ${theme.spacing(3)}px;
    height: ${theme.spacing(3)}px;
    padding: 0;
  `}
`;

const DisabledMixin = css`
  ${({ theme }) => `
    && {
      background: ${theme.palette.primary.light};
      border-color: ${theme.palette.primary.light};
      color: ${theme.palette.text.disabled};
      cursor: default;
    }
  `}
`;

export const Badge = styled(Button)`
  ${({ theme }) => `
    flex:  1  calc(50% - ${theme.spacing(1)}px);
    margin: ${theme.spacing(0.5, 0.5, 0)};
    padding: ${theme.spacing(0.5, 1.5)};
    display: inline-block;
    font-weight: normal;
  `}

  ${({ theme, ...props }) => props.state === "disabled" && DisabledMixin}
  ${({ theme, ...props }) =>
    props.state === "disabled" &&
    `
  && {
    background: ${theme.palette.primary.main};
  }
`}
`;
