import styled, { css } from "styled-components";
import LinkComponent from "@material-ui/core/Link";

const DisabledeMixinn = css`
  ${({ theme }) => `
    color: ${theme.palette.text.disabled};
    cursor: default;
  `}
`;

export const Root = styled(LinkComponent)<{
  clean: boolean;
  disabled: boolean;
}>`
  ${({ theme, ...props }) => `
    color: ${theme.palette.secondary.main};
    cursor: pointer;
    font-weight: bold;

    &:hover {
      text-decoration:  ${props.disabled || props.clean ? "none" : "underline"};
    }
  `}

  ${({ theme, ...props }) => props.disabled && DisabledeMixinn}
`;
