import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { QuizResultsAttributes } from "vt-types";
import { GridContainer } from "../../../components/Grid";
import { Loader } from "../../../components/Loader";
import {
  Challenge,
  QuizAnswersAttributes,
  ChallengeAttributes,
} from "../../../containers/Challenge";
import { Layout } from "../../../containers/Layout";
import { NoMatch } from "../../NoMatch";
import { QuizResults } from "./Results";

interface GetQuizQueryProps {
  quiz: ChallengeAttributes[];
}

const GET_QUIZ_DATA = gql`
  query($token: String!) {
    quiz(token: $token) {
      id
      isCode
      question
      options {
        value
        label
      }
    }
  }
`;

const SubmitQuiz = gql`
  mutation submitQuiz($formValues: [QuizParams!]!) {
    submitQuiz(formValues: $formValues) {
      score
      content
    }
  }
`;

export const Quiz: React.FC<RouteComponentProps<{ token?: string }>> = (
  props
) => {
  const [submitQuiz] = useMutation(SubmitQuiz);
  const [results, setResults] = React.useState<QuizResultsAttributes | null>(
    null
  );

  const { loading, error, data } = useQuery<GetQuizQueryProps>(GET_QUIZ_DATA, {
    variables: { token: props.match.params.token },
  });

  const onSubmit = async (fromValues: QuizAnswersAttributes[]) => {
    try {
      const resultsData = await submitQuiz({
        variables: {
          formValues: fromValues,
        },
      });
      setResults({
        score: resultsData.data.submitQuiz.score,
        content: resultsData.data.submitQuiz.content,
      });
    } catch (error) {
      console.error("Thy has failed onNext.submitQuiz");
    }
  };

  if (error || (data && data.quiz.length <= 0)) {
    console.error("Show appropiate 404 page");
    // @TODO Show 404 content related to quiz
    return <NoMatch title="Quiz not found" {...props} />;
  }

  return (
    <React.Fragment>
      {results ? (
        <Layout>
          <GridContainer>
            <QuizResults
              onReset={() => {
                setResults(null);
              }}
              score={results.score}
              content={results.content}
            />
          </GridContainer>
        </Layout>
      ) : (
        <React.Fragment>
          {loading || !data ? (
            <Loader />
          ) : (
            <React.Fragment>
              <Challenge
                verifiedName={props.match.params.token ?? ""}
                data={data.quiz}
                onSubmit={onSubmit}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
