import ButtonComponent from "@material-ui/core/Button";
import Arrow from "@material-ui/icons/Eject";
import styled from "styled-components";
import { Paper } from "../../Paper";
import { Typography } from "../../Typography";
import { SelectButtonProps, SelectButtonMixin } from "../style";

export const Root = styled(Paper)`
  ${({ theme }) => `
    padding: ${theme.spacing(0.5)}px;
    background: transparent;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
  `}
`;

export const Button = styled(ButtonComponent)<SelectButtonProps>`
  ${SelectButtonMixin}

  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(16)};
  `}
`;

export const IconASC = styled(Arrow)`
  color: inherit;
  font-size: inherit;
`;

export const IconDesc = styled(Arrow)`
  transform: rotate(180deg);
  color: inherit;
  font-size: inherit;
`;

export const Text = styled(Typography)`
  ${({ theme }) => `
    cursor: pointer;
    flex: 1;
    font-weight: bold;
    margin-bottom: ${theme.spacing(1)}px;
    transition: ${theme.transitions.create(["color"], {
      easing: theme.transitions.easing.sharp,
    })};
    &:hover {
      color: ${theme.palette.secondary.main};
    }
  `}
`;

export const Smallprint = styled(Typography)<{ opacity: "1" | "0" }>`
  ${({ theme, ...props }) => `
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    min-height: 15px;
    opacity: ${props.opacity} ;
    margin-top: ${theme.spacing(0.5)}px;
    transition: ${theme.transitions.create(["opacity"], {
      easing: theme.transitions.easing.sharp,
    })};
  `}
`;

export const List = styled.ul`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
  flex-direction: row;
  align-items: center;
`;
