import NavIcon from "@material-ui/icons/AssistantPhotoOutlined";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useHistory } from "react-router-dom";
import { Logo } from "../../../components/Logo";
import { captureEvent } from "../../../utils/firebase";
import { RouteOptions } from "../../../utils/routes";
import {
  CloseButton,
  Footer,
  FooterLink,
  Header,
  HomeItem,
  NavItem,
  NavItemContent,
  NavList,
  NavListItemIcon,
  Root,
} from "./style";
import { windowHeightId } from "../../../utils/theme";

export interface NavAttributes {
  icon?: React.ReactElement;
  selected?: boolean;
  label: string;
  value: string;
}

interface NavProps {
  open: boolean;
  onClose: () => void;
  items: NavAttributes[];
}

export const Nav: React.FC<NavProps> = (props) => {
  const history = useHistory();

  React.useEffect(() => {
    const setVH = () => {
      document.documentElement.style.setProperty(
        windowHeightId,
        `${window.innerHeight * 0.01}px`
      );
    };

    setVH();
    window.addEventListener("resize", setVH);
    return () => window.removeEventListener("resize", setVH);
  }, []);

  return (
    <Root open={props.open} component="nav" square={true} elevation={0}>
      <Header>
        <CloseButton onClick={props.onClose}>
          <CloseIcon fontSize="inherit" color="inherit" />
        </CloseButton>
      </Header>

      <NavList dense={true}>
        <li>
          <NavItem
            isactive={RouteOptions.Home === window.location.pathname ? 1 : 0}
            button={true}
            onClick={async () => {
              await captureEvent({
                category: "nav",
                value: "home",
              });

              history.push(RouteOptions.Home);
              props.onClose();
            }}
          >
            <NavListItemIcon>
              <HomeItem>
                <Logo
                  variant="mark"
                  color={
                    RouteOptions.Home !== window.location.pathname
                      ? "light"
                      : undefined
                  }
                />
              </HomeItem>
            </NavListItemIcon>

            <NavItemContent>Home</NavItemContent>
          </NavItem>
        </li>

        {props.items.map((item) => (
          <li key={item.value}>
            <NavItem
              isactive={item.value === window.location.pathname ? 1 : 0}
              button={true}
              onClick={async () => {
                await captureEvent({
                  category: "nav",
                  value: item.value,
                });

                history.push(item.value);
                props.onClose();
              }}
            >
              <NavListItemIcon color="inherit">
                {item.icon ?? <NavIcon color="inherit" fontSize="inherit" />}
              </NavListItemIcon>

              <NavItemContent>{item.label}</NavItemContent>
            </NavItem>
          </li>
        ))}
      </NavList>
      <Footer>
        <li>
          <FooterLink href={RouteOptions.Privacy}>privacy policy</FooterLink>
        </li>
        <li>
          <FooterLink href={RouteOptions.Contact}>contact us</FooterLink>
        </li>
      </Footer>
    </Root>
  );
};
