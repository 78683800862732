import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BackToTop } from "../../components/BackToTop";
import { Button } from "../../components/Button";
import { GridContainer, GridItem } from "../../components/Grid";
import { Loader } from "../../components/Loader";
import { Paper } from "../../components/Paper";
import { Typography } from "../../components/Typography";
import { CourseList } from "../../containers/CourseList";
import { Layout } from "../../containers/Layout";
import { GetAllCoursesQuery, GET_ALL_COURSES_DATA } from "../../queries";
import { RouteOptions } from "../../utils/routes";

export const Courses: React.FC<RouteComponentProps> = (props) => {
  const { loading, error, data } = useQuery<GetAllCoursesQuery>(
    GET_ALL_COURSES_DATA
  );

  return (
    <Layout>
      <GridContainer>
        <GridItem>
          <Paper>
            <Typography variant="h1" align="center">
              All Courses
            </Typography>
          </Paper>
        </GridItem>

        {loading || error || !data || !data.courses ? (
          <GridItem>
            <Loader error={error ? "Something went wrong" : undefined} />
          </GridItem>
        ) : (
          <React.Fragment>
            <GridItem>
              <CourseList tokens={data.tokens} courses={data.courses} />
            </GridItem>
            <GridItem>
              <Button
                state="inverted"
                color="secondary"
                onClick={async () => {
                  props.history.push(RouteOptions.Roles);
                }}
              >
                browse roles
              </Button>
            </GridItem>
          </React.Fragment>
        )}
      </GridContainer>
      <BackToTop />
    </Layout>
  );
};
