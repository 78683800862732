import FormGroup from "@material-ui/core/FormGroup";
import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Check, Label } from "./style";

interface CheckboxProps {
  name: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  required?: boolean;
  onClick: (value: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  return (
    <FormGroup row={true}>
      <FormControlLabel
        control={
          <Check
            disabled={props.disabled}
            required={props.required && !props.checked}
            checked={props.checked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.onClick(event.target.checked)
            }
            name={props.name}
          />
        }
        label={
          <Label
            disabled={props.disabled || false}
            required={props.required && !props.checked}
            onClick={() => !props.disabled && props.onClick(!props.checked)}
          >
            {props.label}
          </Label>
        }
      />
    </FormGroup>
  );
};
