import Collapse from "@material-ui/core/Collapse";
import BackIcon from "@material-ui/icons/ArrowLeft";
import NextIcon from "@material-ui/icons/ArrowRight";
import React from "react";
import { useHistory } from "react-router-dom";
import { QuizAttributes } from "vt-types";
import { Logo } from "../../components/Logo";
import { PopupConfirm } from "../../components/Popup";
import { SelectBlock } from "../../components/Select/SelectBlock";
import { Typography } from "../../components/Typography";
import { getChallengeTitle } from "../../utils/getters";
import { RouteOptions } from "../../utils/routes";
import { Cursor } from "./Cursor";
import {
  CloseIcon,
  CodeBlock,
  Content,
  ContentWrapper,
  ContinueButton,
  Footer,
  Header,
  LogoWrapper,
  NavWrapper,
  OptionItem,
  Options,
  PreviousButton,
  QuitButton,
  RestartIcon,
  Title,
} from "./style";

export type ChallengeAttributes = Pick<
  QuizAttributes,
  "id" | "question" | "options" | "isCode"
>;

export interface QuizAnswersAttributes {
  questionId: string;
  value: string;
}

interface ChallengeProps {
  data: ChallengeAttributes[];
  verifiedName: string;
  onSubmit: (values: QuizAnswersAttributes[]) => void;
}

export const Challenge: React.FC<ChallengeProps> = (props) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [isResetting, setIsResetting] = React.useState(false);
  const [isQuitting, setIsQuitting] = React.useState(false);
  const [selections, setSelections] = React.useState<QuizAnswersAttributes[]>(
    []
  );

  React.useEffect(() => {
    function onKeyPress(e: any) {
      if (e.key === "Enter" && selections.length > currentStep) {
        setCurrentStep((currentStep) => currentStep + 1);
      }
    }
    window.addEventListener("keypress", onKeyPress);

    return () => window.removeEventListener("keypress", onKeyPress);
  }, [selections.length, currentStep]);

  const onNext = async (value: string) => {
    const selectedQuestion = props.data[currentStep];
    let payload = [...selections];

    if (selectedQuestion) {
      const alreadyAnswered = payload.findIndex(
        (progress) => progress.questionId === selectedQuestion.id
      );
      if (alreadyAnswered >= 0) {
        payload[alreadyAnswered].value = value;
      } else {
        payload.push({
          questionId: selectedQuestion.id,
          value,
        });
      }
    }

    if (currentStep === props.data.length - 1) {
      props.onSubmit(payload);
    } else {
      setSelections(payload);
    }
  };

  const onReset = () => {
    setCurrentStep(0);
    setSelections([]);
    setIsQuitting(false);
    setIsResetting(false);
  };

  const renderFooter = () => {
    return (
      <Header elevation={0} component="footer">
        <NavWrapper>
          <PreviousButton
            // edge="end"
            aria-label="close"
            state={currentStep <= 0 ? "disabled" : undefined}
            onClick={() => setCurrentStep((currentStep) => currentStep - 1)}
            Icon={BackIcon}
          />
          <Title variant="h3" component="h2" align="center">
            {getChallengeTitle(props.data.length, currentStep)}
          </Title>
          <ContinueButton
            color="secondary"
            state={selections.length <= currentStep ? "disabled" : undefined}
            Icon={NextIcon}
            onClick={() => setCurrentStep((currentStep) => currentStep + 1)}
          />
        </NavWrapper>
      </Header>
    );
  };

  const renderHeader = () => {
    return (
      <Footer elevation={0} component="header">
        <NavWrapper>
          <QuitButton
            edge="end"
            aria-label="close"
            onClick={() => setIsQuitting(true)}
            Icon={CloseIcon}
          />
          <LogoWrapper>
            <Logo color="light" />
          </LogoWrapper>
          <ContinueButton
            color="secondary"
            Icon={RestartIcon}
            onClick={() => setIsResetting(true)}
          />
        </NavWrapper>
      </Footer>
    );
  };

  const renderPopup = () => {
    if (isQuitting) {
      return (
        <PopupConfirm
          isOpen={isQuitting}
          title="Quit Challenge"
          content="You'll lose all of your progress if you quit now. Are you sure you want to do this?"
          secondaryCTA={() => history.push(RouteOptions.Tokens)}
          primaryCTA={() => setIsQuitting(false)}
        />
      );
    }

    return (
      <PopupConfirm
        isOpen={isResetting}
        title="Restart Challenge"
        content="You'll lose all of your progress if you restart the process. Are you sure you want to do this?"
        secondaryCTA={onReset}
        secondaryLabel="Restart"
        primaryCTA={() => setIsResetting(false)}
      />
    );
  };

  return (
    <React.Fragment>
      <Cursor />

      {renderPopup()}
      {renderHeader()}

      <Content>
        {props.data.map((item, questionIndex) => (
          <Collapse key={questionIndex} in={questionIndex === currentStep}>
            <Typography variant="h2" gutterBottom={true} align="center">
              {item.question.split("#!").map((part, partIndex) => (
                <React.Fragment
                  key={`question-part-${questionIndex}-${partIndex}`}
                >
                  {part.includes("!!") ? (
                    <CodeBlock data-lang={props.verifiedName}>
                      {part.replace("!!", "")}
                    </CodeBlock>
                  ) : (
                    part
                  )}
                </React.Fragment>
              ))}
            </Typography>
            <ContentWrapper>
              <Options>
                {item.options.map((option) => (
                  <React.Fragment key={option.value}>
                    <OptionItem>
                      <SelectBlock
                        {...option}
                        isCode={item.isCode}
                        onClick={() => onNext(option.value)}
                        selected={Boolean(
                          selections.find(
                            (answer) =>
                              answer.value === option.value &&
                              answer.questionId === item.id
                          )
                        )}
                      />
                    </OptionItem>
                  </React.Fragment>
                ))}
              </Options>
            </ContentWrapper>
          </Collapse>
        ))}
      </Content>

      {renderFooter()}
    </React.Fragment>
  );
};
