// import { Blog } from "../screens/Blog";
import { ContactForm } from "../screens/Contact";
import { Courses } from "../screens/Courses";
import { Privacy } from "../screens/Hygiene/Privacy";
import { Terms } from "../screens/Hygiene/Terms";
// import { Roles } from "../screens/Roles";
import { ViewToken } from "../screens/Tokens";
import { Quiz } from "../screens/Tokens/Quiz";
import { Role } from "../screens/Roles/SingleRole";
import { Roles } from "../screens/Roles";

export enum RouteOptions {
  Home = "/",
  Roles = "/roles",
  Courses = "/courses",
  Tokens = "/tokens",
  TokenQuiz = "/tokens/:token/quiz",
  SingleRole = "/roles/:role",
  Library = "/library",
  Book = "/library/:postId",
  Contact = "/contact",
  RequestRole = "/contact/request",
  Privacy = "/privacy",
  Terms = "/terms",
}

interface RouteAttributes {
  path: RouteOptions;
  component: any;
}

export function makeRolesUrl(role: string) {
  return RouteOptions.SingleRole.replace(":role", role);
}

export function makeQuizUrl(token: string) {
  return RouteOptions.TokenQuiz.replace(":token", token);
}

export function makePostUrl(id: string) {
  return RouteOptions.Book.replace(":postId", id);
}

export const protectedRoutes: RouteAttributes[] = [];

export const publicRoutes: RouteAttributes[] = [
  {
    path: RouteOptions.Tokens,
    component: ViewToken,
  },
  {
    path: RouteOptions.Roles,
    component: Roles,
  },
  {
    path: RouteOptions.SingleRole,
    component: Role,
  },
  {
    path: RouteOptions.Courses,
    component: Courses,
  },
  {
    path: RouteOptions.RequestRole,
    component: ContactForm,
  },
  {
    path: RouteOptions.Contact,
    component: ContactForm,
  },
  {
    path: RouteOptions.Privacy,
    component: Privacy,
  },
  {
    path: RouteOptions.Terms,
    component: Terms,
  },
  {
    path: RouteOptions.Home,
    component: Roles,
  },
];

// @TODO complete the below WIP routes
export const privateRoutes: RouteAttributes[] = [
  {
    path: RouteOptions.TokenQuiz,
    component: Quiz,
  },
  // @TODO fix the below
  // {
  //   path: RouteOptions.Library,
  //   component: Blog,
  // },
  // {
  //   path: RouteOptions.Book,
  //   component: Blog,
  // },
];
