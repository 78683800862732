import React from "react";
import { Button } from "../../components/Button";
import { FeatureViewer } from "../../components/FeatureViewer";
import { GridContainer, GridItem } from "../../components/Grid";
import { HorizontalStat } from "../../components/HorizontalStat";
import { Paper } from "../../components/Paper";
import { Typography } from "../../components/Typography";
import { GetTokenQueryTokenAttributes } from "../../queries";
import { FeatureNames } from "../../utils/config";
import { getTokenStats } from "../../utils/getters";
import { makeQuizUrl, makeRolesUrl } from "../../utils/routes";
import { Badge, BadgeBar, CardHeader, CardTitle, TokenImage } from "./style";
import { useHistory } from "react-router-dom";

export interface CardTokenProps {
  token: GetTokenQueryTokenAttributes;
}

export const CardToken: React.FC<CardTokenProps> = (props) => {
  const history = useHistory();

  return (
    <Paper>
      <GridContainer>
        <GridItem>
          <CardHeader>
            <TokenImage
              alt={props.token.name}
              src={`/images/tokens/${props.token.image}`}
            />
            <CardTitle variant="h2" color="textPrimary">
              {props.token.name}
            </CardTitle>

            {props.token.url && (
              <Button
                size="small"
                state="inverted"
                color="secondary"
                onClick={() => window.open(props.token.url, "_blank")}
              >
                DOCS
              </Button>
            )}
          </CardHeader>

          <HorizontalStat id="token" items={getTokenStats(props.token)} />

          <Typography variant="overline" component="p">
            {/* @TODO refine on content */}
            <strong>Recommended tech roles</strong>
          </Typography>

          <BadgeBar>
            {props.token.roles.map((role) => (
              <React.Fragment key={role.identifier}>
                <Badge
                  state={!role.public ? "disabled" : undefined}
                  fullWidth={false}
                  color="primary"
                  onClick={() => history.push(makeRolesUrl(role.identifier))}
                >
                  {role.name}
                </Badge>
              </React.Fragment>
            ))}
          </BadgeBar>
        </GridItem>

        <FeatureViewer feature={FeatureNames.VerificationJourney}>
          <React.Fragment>
            {props.token.verifiedName !== null && (
              <GridItem>
                <Button
                  color="secondary"
                  onClick={() =>
                    history.push(makeQuizUrl(props.token.verifiedName ?? ""))
                  }
                >
                  start verification
                </Button>
              </GridItem>
            )}
          </React.Fragment>
        </FeatureViewer>
      </GridContainer>
    </Paper>
  );
};
