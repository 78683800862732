import { CourseAttributes } from "vt-types";
import { SortOrderTypes } from "../../components/Select/SelectSort";

interface SortProps {
  courses: CourseAttributes[];
  order: SortOrderTypes;
}

export function sortByPrice(args: SortProps): CourseAttributes[] {
  return args.courses.sort((a, b) => {
    const isAsc = args.order === "asc";

    if (a.signup && b.signup) {
      return 0;
    }

    if (a.signup && !b.signup) {
      return isAsc ? 1 : -1;
    }

    if (!a.signup && b.signup) {
      return isAsc ? -1 : 1;
    }

    return isAsc ? a.price - b.price : b.price - a.price;
  });
}

export function sortByDuration(args: SortProps): CourseAttributes[] {
  return args.courses.sort((a, b) => {
    const isAsc = args.order === "asc";

    if (a.duration === 0 && b.duration === 0) {
      return 0;
    }

    if (a.duration <= 0 && b.duration > 0) {
      return isAsc ? 1 : -1;
    }

    if (a.duration > 0 && b.duration <= 0) {
      return isAsc ? -1 : 1;
    }

    return isAsc ? a.duration - b.duration : b.duration - a.duration;
  });
}
