import styled, { css } from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

export const Root = styled(Dialog)`
  ${({ theme }) => `
   padding: ${theme.spacing(2, 1)};
   border: 2px solid ${theme.palette.secondary.main};
  `}
`;

const PaddingMixin = css`
  ${({ theme }) => `
    padding: ${theme.spacing(2, 2)};
  `}
`;

export const Header = styled(DialogTitle)`
  ${PaddingMixin}
  ${({ theme }) => `
    background: ${theme.palette.secondary.main};
    color: ${theme.palette.secondary.contrastText};
    text-align: center;
  `}
`;

export const Main = styled(DialogContent)`
  ${PaddingMixin}
`;

export const Footer = styled(DialogActions)`
  ${({ theme }) => `
    border-top: 2px solid ${theme.palette.secondary.main};
    margin: ${theme.spacing(0, 0.5)};
  `}
`;
