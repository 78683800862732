import List from "@material-ui/core/List";
import styled from "styled-components";
import { Typography } from "../Typography";

export const Root = styled(List)`
  ${({ theme }) => `
    padding: 0;
    margin: ${theme.spacing(1, 0)};
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    & > li:not(:last-child) {
      border-right: 2px solid ${theme.palette.secondary.main};
    }
  `}
`;

export const Item = styled.li`
  ${({ theme }) => `
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: ${theme.spacing(1, 0)};
    margin: 0;
  `}
`;

export const Label = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.text.disabled};
    margin-top: ${theme.spacing(0.5)}px;
  `}
`;
