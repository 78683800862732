import React from "react";
import { Typography } from "../Typography";
import { Item, Label, Root } from "./style";

export interface StatsAttributes {
  label: string;
  value: string | number;
}
interface HorizontalStatProps {
  id: string;
  items: StatsAttributes[];
}

export const HorizontalStat: React.FC<HorizontalStatProps> = (props) => {
  return (
    <Root>
      {props.items.map((item, index) => (
        <React.Fragment key={`stat-${props.id}-${index}`}>
          <Item>
            <Typography component="span" align="center">
              <strong>{item.value}</strong>
            </Typography>
            <Label variant="overline" component="span" align="center">
              <strong>{item.label}</strong>
            </Label>
          </Item>
        </React.Fragment>
      ))}
    </Root>
  );
};
