import React from "react";
import gsap from "gsap";
import { CursorButtonClass, containerWidth } from "../../utils/theme";
import styled from "styled-components";

export const StyledCursor = styled.div<{ size?: "small" | "large" }>`
  width: var(--size);
  height: var(--size);
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 100;

  ${({ theme, ...props }) =>
    props.size === "large" &&
    `
    --size: 40px;
    border: 2px solid ${theme.palette.secondary.main};
  `}

  ${({ theme, ...props }) => props.size === "small" && "--size: 10px;"}
`;

export const Cursor: React.FC = () => {
  React.useEffect(() => {
    const cursorOuter = document.querySelector(".cursor--large");
    const cursorInner = document.querySelector(".cursor--small");
    let isStuck = false;
    let mouse = {
      x: -100,
      y: -100,
    };

    let cursorOuterOriginalState = {
      width: cursorOuter?.getBoundingClientRect().width,
      height: cursorOuter?.getBoundingClientRect().height,
    };

    const isMobile = window.innerWidth <= containerWidth; //&& window.innerHeight <= 600;

    if (!isMobile) {
      const buttons = document.querySelectorAll(`.${CursorButtonClass}`);

      buttons.forEach((button) => {
        button.addEventListener("pointerenter", handleMouseEnter);
        button.addEventListener("pointerleave", handleMouseLeave);
      });

      document.body.addEventListener("pointermove", updateCursorPosition);
      document.body.addEventListener("pointerdown", () => {
        gsap.to(cursorInner, 0.15, {
          scale: 2,
        });
      });
      document.body.addEventListener("pointerup", () => {
        gsap.to(cursorInner, 0.15, {
          scale: 1,
        });
      });
      updateCursor();
    }

    function updateCursorPosition(e: any) {
      mouse.x = e.pageX;
      mouse.y = e.pageY;
    }

    function updateCursor() {
      gsap.set(cursorInner, {
        x: mouse.x,
        y: mouse.y,
      });

      if (!isStuck) {
        gsap.to(cursorOuter, {
          duration: 0.15,
          x: mouse.x,
          y: mouse.y,
        });
      }

      requestAnimationFrame(updateCursor);
    }

    function handleMouseEnter(e: any) {
      isStuck = true;
      const targetBox = e.currentTarget.getBoundingClientRect();
      gsap.to(cursorOuter, 0.2, {
        x: targetBox.left + targetBox.width / 2,
        y: targetBox.top + targetBox.height / 2,
        width: targetBox.width,
        height: targetBox.height,
      });
    }

    function handleMouseLeave(e: any) {
      isStuck = false;
      gsap.to(cursorOuter, 0.2, {
        width: cursorOuterOriginalState.width,
        height: cursorOuterOriginalState.width,
      });
    }
  }, []);

  return (
    <React.Fragment>
      <StyledCursor className="cursor--large" size="large" />
      <StyledCursor className="cursor--small" size="small" />
    </React.Fragment>
  );
};
