import { ApolloProvider } from "@apollo/react-hooks";
import CssBaseline from "@material-ui/core/CssBaseline";
import NoSsr from "@material-ui/core/NoSsr";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";

import React from "react";
import {
  BrowserRouter as Router,
  Route as RouteComponent,
  RouteComponentProps,
  RouteProps,
  Switch,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { CookieBanner } from "./components/CookieBanner";
import { NoMatch } from "./screens/NoMatch";
import { config } from "./utils/config";
import { pageView } from "./utils/firebase";
import { privateRoutes, protectedRoutes, publicRoutes } from "./utils/routes";
import { theme } from "./utils/theme";

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: config.api.host + config.api.url,
    }),
  ]),
  cache: new InMemoryCache(),
});

const Route: React.FC<
  RouteProps & { component: React.ComponentType<RouteComponentProps<any>> }
> = ({
  component: Component,
  ...rest
}: {
  component: React.ComponentType<RouteComponentProps<any>>;
}) => {
  React.useEffect(() => pageView(), []);
  return (
    <RouteComponent {...rest} render={(props) => <Component {...props} />} />
  );
};

export const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <NoSsr>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Switch>
                {publicRoutes.map((route, routeIndex) => (
                  <Route
                    key={`route-public-${routeIndex}`}
                    {...route}
                    exact={true}
                  />
                ))}

                {protectedRoutes.map((route, routeIndex) => (
                  <Route
                    key={`route-protected-${routeIndex}`}
                    {...route}
                    exact={true}
                  />
                ))}

                {privateRoutes.map(
                  ({ component: Component, path }, routeIndex) => (
                    <RouteComponent
                      key={`route-private-${routeIndex}`}
                      exact={true}
                      path={path}
                      render={(props) =>
                        config.env !== "production" ? (
                          <Component {...props} />
                        ) : (
                          <NoMatch {...props} />
                        )
                      }
                    />
                  )
                )}

                <Route component={NoMatch} />
              </Switch>
            </Router>
            <CookieBanner />
          </ThemeProvider>
        </MuiThemeProvider>
      </NoSsr>
    </ApolloProvider>
  );
};
