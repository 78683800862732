import React from "react";
import { AnswerButton, CodeBlock } from "./style";
import { CursorButtonClass } from "../../../utils/theme";

interface SelectBlocksProps {
  onClick: (value: string) => void;
  value: string;
  label: string;
  selected: boolean;
  isCode?: boolean;
}

export const SelectBlock: React.FC<SelectBlocksProps> = (props) => {
  return (
    <AnswerButton
      className={CursorButtonClass}
      selected={props.selected}
      onClick={() => {
        props.onClick(props.value);
      }}
    >
      <CodeBlock isCode={props.isCode}>{props.label}</CodeBlock>
    </AnswerButton>
  );
};
