import FilterIconComponent from "@material-ui/icons/FilterListOutlined";
import LogoIcon from "@material-ui/icons/EjectOutlined";
import ArrowIcon from "@material-ui/icons/PlayArrowOutlined";
import styled from "styled-components";

export const Logo = styled(LogoIcon)`
  transform: rotate(180deg);
`;

export const BackIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
`;

export const ForwardIcon = styled(ArrowIcon)``;

export const FilterIcon = styled(FilterIconComponent)<{ open?: boolean }>`
  ${({ theme }) => `
    transition: ${theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};
  `}
  ${({ ...props }) =>
    props.open &&
    `
    transform: rotate(180deg);
  `}
`;
