import React from "react";
import { Root } from "./style";

interface ContainerProps {
  children: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = (props) => {
  return <Root>{props.children}</Root>;
};
