import { ButtonProps } from "@material-ui/core/Button";
import React from "react";
import { Root } from "./style";

export type ButtonColorProps = "primary" | "secondary" | "error";

interface Props extends Omit<ButtonProps, "color" | "variant" | "disabled"> {
  state?: "default" | "inverted" | "disabled";
  children: React.ReactNode;
  color?: ButtonColorProps;
}

export const Button: React.FC<Props> = ({
  children,
  state = "default",
  color = "primary",
  ...props
}) => {
  return (
    <Root
      state={state}
      disabled={state === "disabled"}
      type="button"
      variant="contained"
      color={color !== "error" ? color : undefined}
      vcolor={color}
      fullWidth={true}
      disableElevation={true}
      {...props}
    >
      {children}
    </Root>
  );
};
