import React from "react";
import { Root } from "./style";
import { PaperProps } from "@material-ui/core";

export const Paper: React.FC<PaperProps> = ({ children, ...props }) => {
  return (
    <Root square={true} elevation={1} {...props}>
      {children}
    </Root>
  );
};
