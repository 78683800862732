import AppBar from "@material-ui/core/AppBar";
import Link from "@material-ui/core/Link";
import styled, { css } from "styled-components";
import { navWidth } from "../../utils/theme";
import { IconButton } from "@material-ui/core";

export const Root = styled.div<{ open: boolean }>`
  ${({ theme, ...props }) => `
    overflow: ${props.open ? "hidden" : ""};
    display: flex;
    flex-direction: column;
    margin: 0;

  `}
`;

export const LogoLink = styled(Link)`
  flex: 1;
  margin-left: -25px;
  &:hover {
    text-decoration: none;
  }
`;

const OpenMixin = css`
  ${({ theme }) => `
    margin-left: ${navWidth}px;
    width: calc(100% - ${navWidth}px);
    transition: ${theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })};
`}
`;

export const Bar = styled(AppBar)<{ open?: boolean }>`
  ${({ theme }) => `
    && {
      width: auto; //100%;
    }
    background: ${theme.palette.primary.main};
    border-bottom: 2px solid ${theme.palette.secondary.main};
    z-index: ${theme.zIndex.drawer + 1};
    transition: ${theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};

    `}
  ${({ ...props }) => props.open && OpenMixin}
`;

export const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => `
    && {
      min-height: auto;
    }
    padding: ${theme.spacing(0.5, 2)};
  `}
`;

export const NavButtonMixin = css`
  ${({ theme }) => `
    border-radius: 0;
    color: ${theme.palette.primary.contrastText};
    &:hover {
      background: ${theme.palette.primary.dark};
    }
  `}
`;

export const MenuButton = styled(IconButton)<{ open?: boolean }>`
  ${NavButtonMixin}
  ${({ theme, ...props }) => `
    opacity: ${props.open ? "0" : "1"};
    cursor:  ${props.open ? "default" : "pointer"};
  `}
`;

export const Main = styled.main<{ open?: boolean }>`
  ${({ theme, ...props }) => `
    width: 100%;
    margin-left: ${props.open ? `${navWidth}px` : undefined};
    transition: ${theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};
  `}
`;
