import React from "react";
import { FeatureNames, isFeatured } from "../../utils/config";

export interface FeatureViewerProps {
  children: React.ReactNode;
  feature: FeatureNames;
}

export const FeatureViewer: React.FC<FeatureViewerProps> = (props) => {
  return (
    <React.Fragment>
      {isFeatured(props.feature) && props.children}
    </React.Fragment>
  );
};
