import Divider from "@material-ui/core/Divider";
import styled from "styled-components";

export const Root = styled(Divider)`
  ${({ theme }) => `
    background-color: ${theme.palette.secondary.main};
    height: 2px;
    margin: ${theme.spacing(0.5, 0)};
  `}
`;
