import Typography from "@material-ui/core/Typography";
import ResultsIcon from "@material-ui/icons/NewReleases";
import React from "react";
import { GridItem } from "../../../components/Grid";
import { Paper } from "../../../components/Paper";
import { ProgressBar } from "../../../components/ProgressBar";
import { Button } from "../../../components/Button";

interface QuizResultsProps {
  onReset: () => void;
  score: number;
  content: string;
}

export const QuizResults: React.FC<QuizResultsProps> = (props) => {
  return (
    <React.Fragment>
      <GridItem>
        <Paper>
          <Typography variant="h1" align="center">
            Results are in! <ResultsIcon color="primary" />
          </Typography>
        </Paper>
      </GridItem>
      <GridItem>
        <ProgressBar
          color="secondary"
          value={props.score}
          label={`${props.score}% verified`}
        />

        <Typography variant="body2">{props.content}</Typography>
      </GridItem>

      <GridItem>
        <Button onClick={props.onReset}>Go again</Button>
      </GridItem>
    </React.Fragment>
  );
};
