import * as firebase from "firebase/app";
import axios from "axios";
import ReactGA from "react-ga";
import "firebase/functions";
import "firebase/analytics";
import { config } from "./config";

if (["production", "uat"].includes(config.env)) {
  ReactGA.initialize(config.firebase.measurementId);
}

firebase.initializeApp(config.firebase);
export const firebaseClient = firebase;

export const pageView = () =>
  ReactGA.pageview(window.location.pathname + window.location.search);

export const captureEvent = async (args: {
  value: string;
  category?: "nav" | "browse" | "back" | "filter" | "affiliate-course";
}): Promise<void> => {
  return ReactGA.event({
    category: args.category || "view",
    action: args.value,
  });
};

export const sendEmail = (values: {
  message: string;
  name?: string;
  email: string;
}) => {
  let subject = "Enquiry";
  const payload = {
    subject,
    name: values.name ?? "Unknown Player",
    email: values.email,
    body: values.message,
  };

  return axios.post(`${config.api.host}/sendEmail`, payload);
};
