import Input from "@material-ui/core/Input";
import { Form as FormRoot } from "formik";
import styled from "styled-components";
import { FieldBorderMixin } from "../../utils/theme";
import IconButton from "@material-ui/core/IconButton";

export const Form = styled(FormRoot)`
  width: 100%;
`;

export const Root = styled.div`
  width: 100%;
  display: flex;
`;

export const SearchButton = styled(IconButton)`
  ${FieldBorderMixin}
  ${({ theme }) => `
    && {
      border: none;
      border-radius: 0;
      color: ${theme.palette.secondary.main};
      font-size: ${theme.typography.pxToRem(16)};
      width: 36px;
      height: 36px;
      margin-left: 0;
    }
  `}
`;

export const TextField = styled(Input)`
  ${({ theme }) => `
    flex: 1;
    font-weight: normal;
    width: 100%;
    padding-left: ${theme.spacing(1)}px;
  `}
`;
