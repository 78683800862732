import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import styled, { css } from "styled-components";
import { ButtonIcon } from "../../components/ButtonIcon";
import { Typography } from "../../components/Typography";
import { containerWidth } from "../../utils/theme";
import ClearIcon from "@material-ui/icons/ExitToApp";
import ResetIcon from "@material-ui/icons/Loop";

const barHeight = 60;

export const Footer = styled(AppBar)`
  ${({ theme }) => `
    position: relative;
    border-bottom: 2px solid ${theme.palette.secondary.main};
  `}
`;

export const Header = styled(AppBar)`
  ${({ theme }) => `
    position: relative;
    border-top: 2px solid ${theme.palette.secondary.main}
  `}
`;

export const NavWrapper = styled(Toolbar)`
  && {
    height: ${barHeight}px;
    min-height: auto;
    align-items: center;
  }
`;

const FooterButtonMixin = css`
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    font-weight: normal;
  `}
`;

export const CloseIcon = styled(ClearIcon)`
  ${FooterButtonMixin}
  ${({ theme }) => `
    transform: rotate(180deg);
  `}
`;

export const RestartIcon = styled(ResetIcon)`
  ${FooterButtonMixin}
`;

const HeaderButtonMixin = css`
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(24)};
    font-weight: bold;
    margin: 0;
    width: 70px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const QuitButton = styled(ButtonIcon)`
  ${HeaderButtonMixin}
  ${({ theme }) => `
    background: ${theme.palette.error.main};
    color:${theme.palette.error.contrastText};

    &:hover {
      background:${theme.palette.error.dark};
      color:${theme.palette.error.contrastText};
    }

  `}

  ${({ theme, ...props }) =>
    props.state === "disabled" &&
    `
    && {
      background: ${theme.palette.error.main};
      opacity: .2;
      color:${theme.palette.error.contrastText};
    }
  `}
`;

const DisabledButtonIconMixin = css`
  ${({ theme }) =>
    `
  && {
    background: ${theme.palette.common.black};
    opacity: .5;
    color:${theme.palette.text.disabled};
  }
`}
`;

export const PreviousButton = styled(ButtonIcon)`
  ${HeaderButtonMixin}
  ${({ theme }) => `
    background: transparent;
    border: 2px solid ${theme.palette.secondary.main};
    color:${theme.palette.secondary.main};

    &:hover {
      background:${theme.palette.secondary.main};
      color:${theme.palette.secondary.contrastText};
    }

  `}

  ${({ theme, ...props }) =>
    props.state === "disabled" && DisabledButtonIconMixin}
`;

export const ContinueButton = styled(ButtonIcon)`
  ${HeaderButtonMixin}
  ${({ theme, ...props }) =>
    props.state === "disabled" &&
    `
      && {
        background: ${theme.palette.common.black};
        opacity: .5;
        color:${theme.palette.text.disabled};
      }
  `}
`;

const BarWrapperMixin = css`
  flex: 1;
`;

export const LogoWrapper = styled.div`
  ${BarWrapperMixin}
  transform: translateX(-25px);
`;

export const Title = styled(Typography)`
  ${BarWrapperMixin}
`;

export const Content = styled.main`
  ${({ theme }) => `
    min-height: calc(100vh - ${(barHeight + 2) * 2}px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(0, 2)};
  `}
`;

export const ContentWrapper = styled.div``;

export const Options = styled(List)`
  ${({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    ${theme.breakpoints.up("md")} {
      min-width: ${containerWidth / 2}px;
    }
  `}
`;

export const OptionItem = styled.li`
  ${({ theme }) => `
    flex: 1 1 50%;
    min-height: 180px;
    display: table-cell;
    padding: ${theme.spacing(1, 1)};
  `}
`;

export const CodeBlock = styled.pre`
  ${({ theme }) => `
    background: ${theme.palette.common.black};
    color: ${theme.palette.text.secondary};
    font-size: ${theme.typography.pxToRem(16)};
    padding: ${theme.spacing(2, 2)};
    margin: ${theme.spacing(1, 1, 0)};
    padding-top: 45px;
    font-weight: normal;
    text-align: left;
    position: relative;
    user-select: none;
    z-index: 100;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%; //calc(100% +${theme.spacing(1.5) * 2}px);
      text-transform: uppercase;
      content: attr(data-lang);
      padding: ${theme.spacing(1, 1)};
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
    }
  `}
`;
