import Fade from "@material-ui/core/Fade";
import styled from "styled-components";
import { ButtonIcon } from "../ButtonIcon";

export const Root = styled(Fade)`
  ${({ theme }) => `
    position: fixed;
    top: ${theme.spacing(5)}px;
    right: ${theme.spacing(2)}px;
    z-index: 10000;
  `}
`;

export const Button = styled(ButtonIcon)`
  ${({ theme }) => `
    border: 1px solid ${theme.palette.secondary.main};
  `}
`;
