import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Paper from "@material-ui/core/Paper";
import styled, { css } from "styled-components";
import { Typography } from "../../../components/Typography";
import { navWidth, windowHeightId } from "../../../utils/theme";
import { IconButton } from "@material-ui/core";
import { NavButtonMixin } from "../style";

export const Root = styled(Paper)<{ open: boolean }>`
  ${({ theme, ...props }) => `
    background: ${theme.palette.primary.main};
    flex-shrink: 0;
    position: fixed;
    white-space: nowrap;
    overflow-x: hidden;
    min-height: 100vh;
    width: ${props.open ? `${navWidth}px` : 0};
    z-index: 100;
    transition: ${theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};
  `}
`;

export const Header = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: ${theme.spacing(0.5, 2)};
    color: ${theme.palette.primary.contrastText};

  `}
`;

export const CloseButton = styled(IconButton)`
  ${NavButtonMixin}
  ${({ theme }) => `
  &:hover {
      background: ${theme.palette.primary.dark};
    }
  `}
`;

export const NavList = styled(List)`
  padding: 0;
  height: calc((var(${windowHeightId}, 1vh) * 100) - 93px);
  overflow: scroll;
  position: relative;
`;

const ActiveItemMixin = css<{ paper?: boolean }>`
  ${({ theme, ...props }) => `
    font-weight: bold;
    background-color:  ${
      props.paper
        ? theme.palette.background.paper
        : theme.palette.background.default
    };
    &:hover {
      background-color:  ${
        props.paper
          ? theme.palette.background.paper
          : theme.palette.background.default
      };
    }
  `}
`;

export const NavListItemIcon = styled(ListItemIcon)`
  ${({ theme }) => `
    color: inherit;
    min-width: 35px;
    font-size: ${theme.typography.pxToRem(21)};
  `}
`;

export const HomeItem = styled.div`
  width: 20px;
  height: 20px;
  transform: translateY(-5px);
`;

export const NavItem = styled(ListItem)<{ isactive: number; paper?: boolean }>`
  ${({ theme }) => `
    padding: ${theme.spacing(4, 2)};
    color: ${theme.palette.primary.contrastText};
    &:hover {
      background: ${theme.palette.primary.dark};
    }
  `}
  ${({ ...props }) => props.isactive && ActiveItemMixin}
`;

export const NavItemContent = styled(Typography)`
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(14)};
    font-weight: bold;
  `}
`;

export const Main = styled.main<{ open?: boolean }>`
  ${({ theme, ...props }) => `
    width: 100%;
    padding: ${theme.spacing(2, 0)};
    margin-left: ${props.open ? `${navWidth}px` : undefined};
    transition: ${theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};

  `}
`;

export const Footer = styled(List)`
  ${({ theme }) => `
    border-top: 1px solid ${theme.palette.secondary.main};
    padding: ${theme.spacing(0.5, 1)};
    margin: ${theme.spacing(0, 1)};
    display: flex;
    justify-content: space-between;
  `}
`;

export const FooterLink = styled.a`
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(11)};
    color: ${theme.palette.primary.contrastText};
    text-decoration: none;
  `}
`;
