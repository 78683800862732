import React from "react";
import {
  Root,
  Button,
  IconASC,
  IconDesc,
  Text,
  Smallprint,
  List,
} from "./style";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { PaperProps } from "@material-ui/core";

export type SortOrderTypes = "asc" | "desc";

function getSortLabel(value?: SortOrderTypes) {
  if (!value) {
    return "";
  }

  return value === "asc" ? "(low-high)" : "(high-low)";
}

interface SelectSortProps {
  component?: PaperProps["component"];
  label: string;
  order?: SortOrderTypes;
  onClick: (order: SortOrderTypes) => void;
}

interface SelectSortListProps {
  children: React.ReactNode;
}

export const SelectSortList: React.FC<SelectSortListProps> = (props) => {
  return <List>{props.children}</List>;
};

export const SelectSort: React.FC<SelectSortProps> = (props) => {
  return (
    <Root component={props.component} elevation={0}>
      <Text
        variant="overline"
        onClick={() => props.onClick(props.order === "asc" ? "desc" : "asc")}
      >
        {props.label}
      </Text>
      <ButtonGroup variant="outlined" aria-label={`order ${props.label}`}>
        <Button
          color="primary"
          selected={props.order === "asc"}
          onClick={() => props.onClick("asc")}
        >
          <IconASC />
        </Button>
        <Button
          color="primary"
          selected={props.order === "desc"}
          onClick={() => props.onClick("desc")}
        >
          <IconDesc />
        </Button>
      </ButtonGroup>
      <Smallprint
        variant="overline"
        opacity={props.order !== null ? "1" : "0"}
        component="span"
      >
        {getSortLabel(props.order)}
      </Smallprint>
    </Root>
  );
};
