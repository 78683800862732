import { createMuiTheme, lighten, darken } from "@material-ui/core";
import { css } from "styled-components";

const bodyFont = ["'Roboto'", "sans-serif"].join(",");
const headingFont = `'Alatsi','Roboto',${bodyFont}`;

export const navWidth = 240;
export const containerWidth = 900;
export const blogContainerWidth = 700;
export const windowHeightId = "--vh";
export const CursorButtonClass = "answer--block";

const isDark = false;

export const primaryColor = "#FFD524";
export const secondaryColor = "#3A1475"; //"#4B1490";

const lightUIColors = {
  primary: {
    main: primaryColor,
    light: "#FEF6D7", //lighten(primaryColor, 0.8),
    dark: "#F1C922", //darken(primaryColor, 0.4), //darken(primaryColor, 0.05),
    contrastText: secondaryColor,
  },
  secondary: {
    main: secondaryColor,
    dark: "#4B1490", // darken(secondaryColor, 0.15),
  },
  common: {
    black: "#1C2E34",
    white: "#FEFEFE",
  },
  background: {
    default: "#F5F6FA",
    paper: "#FFFFFF",
  },
  text: {
    primary: "#1C2E34",
    secondary: primaryColor,
    disabled: "#5A626D",
  },
};

const darkUIColors = {
  common: { black: "#1C2E34", white: "#FEFEFE" },
  background: {
    default: "#1C2E34",
    paper: "#101A1D",
  },
  text: {
    primary: "#FEFEFE",
    secondary: "#FEDEA3",
    disabled: "#ACACAC",
  },
  primary: {
    main: "#F1F227",
    light: "#1D2220",
    contrastText: "#1C2E34",
  },
  secondary: {
    main: "#D1005E",
    light: lighten("#D1005E", 0.9),
  },
};

const palette = isDark ? darkUIColors : lightUIColors;

const defaultTheme = createMuiTheme({
  palette: {
    type: isDark ? "dark" : "light",
    ...palette,
    info: {
      main: "#2574A9",
      contrastText: palette.common.white,
    },
    warning: {
      main: "#463C83",
      contrastText: palette.common.white,
    },
    success: {
      main: "#FAEE4B",
      contrastText: palette.common.white,
    },
    error: {
      main: "#CD005C",
      dark: darken("#D1005E", 0.2),
      // dark: "#EF6563",
      contrastText: palette.common.white,
    },
  },
});

export const theme = createMuiTheme({
  ...defaultTheme,
  shape: {
    borderRadius: 0,
  },
  palette: defaultTheme.palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 760,
      lg: 1240,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: bodyFont,
    h1: {
      fontSize: defaultTheme.typography.pxToRem(32),
      fontFamily: headingFont,
      fontWeight: "bold",
    },
    h2: {
      fontSize: defaultTheme.typography.pxToRem(26),
      // color: defaultTheme.palette.primary.main,
      fontFamily: headingFont,
    },
    h3: {
      fontSize: defaultTheme.typography.pxToRem(22),
      fontFamily: headingFont,
    },
    h4: {
      fontSize: defaultTheme.typography.pxToRem(20),
      fontFamily: headingFont,
    },
    subtitle1: {
      fontSize: defaultTheme.typography.pxToRem(18),
    },
    body1: {
      fontSize: defaultTheme.typography.pxToRem(16),
    },
    body2: {
      fontSize: defaultTheme.typography.pxToRem(14),
    },
    overline: {
      fontSize: defaultTheme.typography.pxToRem(12),
      lineHeight: 1,
    },
  },
});

export const FieldBorderMixin = css`
  padding: ${theme.spacing(1)}px;
  font-size: ${theme.typography.pxToRem(18)};
  position: relative;
  &:before {
    content: "";
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid
      ${isDark ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.42)"};
    pointer-events: none;
  }
  &:hover {
    &:before {
      border-bottom: 2px solid ${theme.palette.primary.main};
    }
  }
`;
