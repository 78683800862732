import { gql } from "apollo-boost";
import { RoleAttributes, CourseAttributes, TokenAttributes } from "vt-types";

const PROVIDER_PARTS = gql`
  fragment ProviderParts on CourseProvider {
    id
    name
    url
  }
`;

const ROLE_FRAGMENT = gql`
  fragment RoleParts on Role {
    id
    identifier
    public
    name
    description
    salary
    complexity
  }
`;

export interface GetAllCoursesQuery {
  courses: Array<
    Pick<
      CourseAttributes,
      | "id"
      | "url"
      | "title"
      | "description"
      | "price"
      | "signup"
      | "duration"
      | "level"
      | "provider"
      | "tokens"
    >
  >;
  tokens: TokenAttributes[];
}

export const GET_ALL_COURSES_DATA = gql`
  query getCourses {
    courses {
      id
      url
      title
      description
      price
      level
      duration
      signup
      provider {
        ...ProviderParts
      }
      tokens {
        id
        name
      }
    }

    tokens {
      id
      name
    }
  }
  ${PROVIDER_PARTS}
`;

export interface GetRolesDataQuery {
  roles: Array<
    Pick<
      RoleAttributes,
      | "id"
      | "identifier"
      | "public"
      | "description"
      | "salary"
      | "complexity"
      | "name"
      | "responsibilities"
    > & {
      courses: Array<Pick<CourseAttributes, "id">>;
    }
  >;
}

export const GET_ROLES_DATA = gql`
  query getRoles {
    roles {
      ...RoleParts
      courses {
        id
      }
    }
  }
  ${ROLE_FRAGMENT}
`;

export interface GetRoleDataQuery {
  role: Pick<
    RoleAttributes,
    | "id"
    | "identifier"
    | "public"
    | "description"
    | "salary"
    | "complexity"
    | "name"
    | "responsibilities"
    | "tokens"
  > & {
    courses: Array<
      Pick<
        CourseAttributes,
        | "id"
        | "url"
        | "title"
        | "description"
        | "price"
        | "signup"
        | "duration"
        | "level"
        | "provider"
        | "tokens"
      >
    >;
  };
}

export const GET_ROLE_DATA = gql`
  query getRole($role: ID!) {
    role(id: $role) {
      ...RoleParts
      responsibilities
      tokens {
        id
        name
      }
      courses {
        id
        url
        title
        description
        price
        level
        duration
        signup
        provider {
          ...ProviderParts
        }
        tokens {
          id
          name
        }
      }
    }
  }
  ${ROLE_FRAGMENT}
  ${PROVIDER_PARTS}
`;

export type GetTokenRolesQuery = Pick<
  RoleAttributes,
  "identifier" | "name" | "public"
>;

export type GetTokenQueryTokenAttributes = Pick<
  TokenAttributes,
  | "id"
  | "name"
  | "url"
  | "rating"
  | "image"
  | "type"
  | "complexity"
  | "verifiedName"
> & {
  roles: GetTokenRolesQuery[];
};

export type GetTokensQuery = {
  roles: Array<Pick<RoleAttributes, "identifier" | "verifiedName">>;
  tokens: GetTokenQueryTokenAttributes[];
};

export const GET_TOKENS_DATA = gql`
  query getTokens {
    roles {
      identifier
      verifiedName
    }

    tokens {
      id
      name
      url
      type
      image
      verifiedName
      complexity
      rating
      roles {
        identifier
        name
        public
      }
    }
  }
`;
