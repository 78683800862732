import { IconButtonProps } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import styled, { css } from "styled-components";
import {
  OutlinedButtonMixin,
  DisabledButtonMixin,
  ErrorButtonMixin,
} from "../Button/style";

interface ButtonIconStyleProps extends IconButtonProps {
  state: "default" | "inverted" | "disabled";
  vcolor?: "primary" | "secondary" | "error";
}

export const ButtonIconMixin = css<{
  vcolor?: "primary" | "secondary" | "error";
}>`
  ${({ theme, ...props }) => `
    background: ${theme.palette[props.vcolor ?? "primary"].main};
    border: 2px solid ${theme.palette[props.vcolor ?? "primary"].main};
    border-color: transparent;
    border-radius: 0;
    color: ${theme.palette.primary.contrastText};
    font-size: ${theme.typography.pxToRem(14)};

    &:hover {
      background: ${
        props.vcolor === "primary"
          ? theme.palette.primary.dark
          : theme.palette[props.vcolor ?? "secondary"].light
      };
    }
  `}
`;

const ContainedSecondaryMixin = css`
  ${({ theme }) => `
    background:${theme.palette.secondary.main};
    color: ${theme.palette.secondary.contrastText};
    &:hover {
      color: ${theme.palette.secondary.contrastText};
      background: ${theme.palette.secondary.dark};
    }
  `}
`;

const LargeButtonIconMixin = css`
  ${({ theme }) =>
    `
    && {
      font-size: ${theme.typography.pxToRem(16)};
      padding: ${theme.spacing(1)}px;
    }
  `}
`;

export const Root = styled(IconButton)<ButtonIconStyleProps>`
  ${ButtonIconMixin}


  ${({ ...props }) => props.vcolor === "secondary" && ContainedSecondaryMixin}
  ${({ ...props }) => props.vcolor === "error" && ErrorButtonMixin}

  ${({ ...props }) => props.state === "inverted" && OutlinedButtonMixin}
  ${({ ...props }) => props.state === "disabled" && DisabledButtonMixin}
  ${({ ...props }) => props.size === "medium" && LargeButtonIconMixin}

`;
