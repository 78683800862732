import AppBar from "@material-ui/core/AppBar";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import styled from "styled-components";
import { Typography } from "../../components/Typography";

export const Root = styled(AppBar)`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    margin: ${theme.spacing(1, 0, 2)};
    overflow: scroll;
    max-height: 100vh;
    background: ${theme.palette.background.paper};
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const FilterContainer = styled(ExpansionPanelDetails)`
  ${({ theme }) => `
    padding:${theme.spacing(2, 2, 1)};
  `}
`;

export const FilterLabel = styled(Typography)`
  font-weight: bold;
`;

export const FilterList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const FilterListItem = styled.li`
  ${({ theme }) => `
    display: inline-block;
    margin: ${theme.spacing(1, 1)};
    margin-left: 0;
  `}
`;
