import styled from "styled-components";
import { Button } from "../../components/Button";
import { Paper } from "../../components/Paper";
import { Typography } from "../../components/Typography";
import { windowHeightId } from "../../utils/theme";

export const Title = styled(Typography)`
  ${({ theme }) => `
    display:flex;
    align-items: flex-end;
    color: ${theme.palette.primary.contrastText};
    font-size: ${theme.typography.pxToRem(48)};
    margin-bottom: ${theme.spacing(1)}px;
    font-weight: bold;
    ${theme.breakpoints.up("sm")} {
      font-size: ${theme.typography.pxToRem(60)};
    }

    ${theme.breakpoints.up("md")} {
      font-size: ${theme.typography.pxToRem(70)};
    }
  `}
`;

export const LogoWrapper = styled.div`
  ${({ theme }) => `
    transform: translateX(8px);
    width: 35px;
    ${theme.breakpoints.up("sm")} {
      width: 42px;
    }
    ${theme.breakpoints.up("md")} {
      width: 50px;
    }
`}
`;

export const Subtitle = styled(Typography)`
  ${({ theme }) => `
    font-size: ${theme.typography.body1.fontSize};
    ${theme.breakpoints.up("sm")} {
      font-size: ${theme.typography.pxToRem(22)};
    }
  `}
`;

export const Heading = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.primary.contrastText};
    text-transform: capitalize;
    cursor: pointer;
  `}
`;

const bannerRotation = "3deg";

export const Banner = styled(Paper)`
  ${({ theme }) => `
    background: ${theme.palette.primary.main};
    padding: ${theme.spacing(5, 0, 2)};

    &:before {
      background: ${theme.palette.primary.main};
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: calc((var(${windowHeightId}, 1vh) * 100) - 10vh);
      transform: skewY(-${bannerRotation}) translateY(-10vh);
      z-index: -10;
      ${theme.breakpoints.up("md")} {
        height: 55vh;
      }
    }
  `}
`;

export const BannerContent = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

  `}
`;

export const Card = styled(Paper)`
  ${({ theme }) => `
    padding: ${theme.spacing(3, 2, 2)};
  `}
`;

export const CardContent = styled(Typography)`
  min-height: 55px;
  display: flex;
  align-items: center;
`;

export const CardButton = styled(Button)`
  ${({ theme }) => `
    font-size: ${theme.typography.h4.fontSize};
    min-height: 180px;


    ${theme.breakpoints.up("md")} {
      width: 100%;
      min-height: 250px;
    }
  `}
`;

export const CTA = styled(Button)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(1)}px;
    // padding: ${theme.spacing(0.5, 1)};
    max-width: 250px;

    ${theme.breakpoints.up("sm")} {
      max-width: 300px;

    }
  `}
`;
