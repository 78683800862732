import { css } from "styled-components";

export interface SelectButtonProps {
  selected: boolean;
  disabled?: boolean;
}

const ActiveSelectButtonMixin = css`
  ${({ theme }) => `
    && {
      background: ${theme.palette.primary.main};
      border-color:  ${theme.palette.primary.main};
    }
  `}
`;

const DisabledSelectMixin = css`
  ${({ theme }) => `
    && {
      color: ${theme.palette.text.disabled};
      border: none;
    }
  `}
`;

export const SelectButtonMixin = css<SelectButtonProps>`
  ${({ theme }) => `
    background: ${theme.palette.primary.light};
    border: 1px solid ${theme.palette.primary.dark};
    border-radius: 0;
    color: ${theme.palette.secondary.main};

    &:hover {
      background: ${theme.palette.primary.dark};
    };
  `}

  ${({ theme, ...props }) =>
    props.disabled
      ? DisabledSelectMixin
      : props.selected && ActiveSelectButtonMixin}
`;
