import { FormikValues } from "formik";
import React from "react";
import { Label } from "../Label";
import { InputWrapper } from "../TextWrapper";
import { Field } from "./style";

interface TextAreaProps {
  name: string;
  label: string;
  value: FormikValues;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  required?: boolean;
  placeholder?: string;
  error?: string;
}

export const TextArea: React.FC<TextAreaProps> = (props) => {
  return (
    <InputWrapper>
      <Label
        error={props.error}
        required={props.required}
        name={props.name}
        label={props.label}
      />

      <Field
        id={props.name}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={Boolean(props.error)}
        fullWidth={true}
        placeholder={props.placeholder}
        multiline={true}
        rows={4}
      />
    </InputWrapper>
  );
};
