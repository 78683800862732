import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import {
  SortOrderTypes,
  SelectSortList,
  SelectSort,
} from "../../components/Select/SelectSort";
import { ButtonIcon } from "../../components/ButtonIcon";
import { SearchField } from "../../components/Search";
import { captureEvent } from "../../utils/firebase";
import { GridContainer, GridItem } from "../../components/Grid";
import { Button } from "../../components/Button";
import { Icon } from "../../components/Icon";
import { SelectPill } from "../../components/Select/SelectPill";
import {
  Root,
  Header,
  FilterContainer,
  FilterLabel,
  FilterList,
  FilterListItem,
} from "./style";

export interface CourseFilterProps {
  price: SortOrderTypes | null;
  duration: SortOrderTypes | null;
  level: SortOrderTypes | null;
  authors: string[];
  tokens: string[];
}

export const initialCourseFilters: CourseFilterProps = {
  price: null,
  duration: null,
  level: null,
  authors: [],
  tokens: [],
};

export interface SelectOptions {
  value: string;
  label: string;
  count: number;
  selected: boolean;
  disabled?: boolean;
}

interface FitlerBarProps {
  dirty: boolean;
  filters: CourseFilterProps;
  tokens: SelectOptions[];
  providers: SelectOptions[];
  onReset: () => void;
  onSearch: (term: string) => void;
  onSort: (filters: CourseFilterProps) => void;
  onFilter: (id: string) => void;
  onProviderFilter: (id: string) => void;
  count: number;
}

export const FilterCourses: React.FC<FitlerBarProps> = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <Root position="sticky" component="section" elevation={1} square={true}>
      <ExpansionPanel square={true} elevation={0} expanded={expanded}>
        <Header>
          <ButtonIcon
            size="lg"
            edge={false}
            onClick={() => setExpanded(!expanded)}
          >
            <Icon selected={expanded} variant="filter" />
          </ButtonIcon>
          <SearchField
            label="Search course by title"
            onSubmit={async (value) => {
              await captureEvent({
                category: "filter",
                value: `search-[${value}]`,
              });
              props.onSearch(value);
            }}
          />
        </Header>
        <FilterContainer>
          <GridContainer>
            <GridItem>
              <SelectSortList>
                <SelectSort
                  component="li"
                  label="level"
                  order={props.filters.level ?? undefined}
                  onClick={async (order) => {
                    await captureEvent({
                      category: "filter",
                      value: `level-${order}`,
                    });

                    const payload = {
                      ...props.filters,
                      level: props.filters.level === order ? null : order,
                    };
                    props.onSort(payload);
                  }}
                />
                <SelectSort
                  component="li"
                  label="price"
                  order={props.filters.price ?? undefined}
                  onClick={async (order) => {
                    await captureEvent({
                      category: "filter",
                      value: `price-${order}`,
                    });

                    const payload = {
                      ...props.filters,
                      price: props.filters.price === order ? null : order,
                    };
                    props.onSort(payload);
                  }}
                />
              </SelectSortList>
            </GridItem>

            <GridItem>
              <FilterLabel variant="overline">Recommended Tokens</FilterLabel>
              <FilterList>
                {props.tokens.map((token) => (
                  <FilterListItem key={token.value}>
                    <SelectPill
                      checked={token.selected}
                      onClick={() => props.onFilter(token.value)}
                      onRemove={() => props.onFilter(token.value)}
                      label={`${token.label}`}
                      disabled={token.disabled}
                    />
                  </FilterListItem>
                ))}
              </FilterList>
            </GridItem>
            <GridItem>
              <FilterLabel variant="overline">Organisations</FilterLabel>
              <FilterList>
                {props.providers.map((author) => (
                  <FilterListItem key={author.value}>
                    <SelectPill
                      checked={author.selected}
                      onClick={() => props.onProviderFilter(author.value)}
                      onRemove={() => props.onProviderFilter(author.value)}
                      label={`${author.label}`}
                    />
                  </FilterListItem>
                ))}
              </FilterList>
            </GridItem>

            <GridItem>
              <Button
                state={!props.dirty ? "disabled" : undefined}
                color="secondary"
                onClick={async () => {
                  await captureEvent({
                    category: "filter",
                    value: "reset",
                  });

                  props.onReset();
                }}
              >
                clear filters
              </Button>
            </GridItem>
          </GridContainer>
        </FilterContainer>
      </ExpansionPanel>
    </Root>
  );
};
