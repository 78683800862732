import React from "react";
import { Button } from "../../components/Button";
import { Typography } from "../../components/Typography";
import { Root, Header, Main, Footer } from "./style";

export interface QuizAnswersAttributes {
  questionId: string;
  value: string;
}

interface PopupProps {
  title: string;
  content: string;
  isOpen: boolean;
  primaryCTA: () => void;
  secondaryCTA: () => void;
  secondaryLabel?: string;
  onBackdropClick?: () => void;
}

export const PopupConfirm: React.FC<PopupProps> = (props) => {
  return (
    <Root
      fullWidth={true}
      open={props.isOpen}
      onClose={props.primaryCTA}
      onBackdropClick={props.onBackdropClick}
      aria-labelledby={props.title.replace(" ", "-")}
    >
      <Header>{props.title}</Header>
      <Main>
        <Typography>{props.content}</Typography>
      </Main>
      <Footer>
        <Button onClick={props.secondaryCTA} color="error">
          {props.secondaryLabel ?? "Quit"}
        </Button>
        <Button onClick={props.primaryCTA} color="primary" autoFocus={true}>
          Continue
        </Button>
      </Footer>
    </Root>
  );
};
