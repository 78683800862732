import styled from "styled-components";
import { Typography } from "../Typography";

export const Root = styled.div`
  ${({ theme }) => `
    background: ${theme.palette.primary.main};
    border: 2px solid ${theme.palette.primary.contrastText};
    color: ${theme.palette.primary.contrastText};
    padding: ${theme.spacing(1, 1)};

    margin: 0;
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw - ${theme.spacing(2)}px);
    z-index: 1000000;
    left: 0;

  `}
`;

export const CookieWrapper = styled.div`
  ${({ theme }) => `
    flex: 1;
    display: flex;
    flex-direction: column;
    ${theme.breakpoints.up("md")} {
      flex-direction: row;
      justify-content: center;
    }
  `}
`;

export const CookieContent = styled(Typography)`
  ${({ theme }) => `
    font-size: ${theme.typography.pxToRem(14)};
  `}
`;

export const CookieLink = styled.a`
  color: inherit;
  font-size: inherit;
`;
