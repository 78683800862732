import styled from "styled-components";
import { Typography } from "../Typography";
import { Paper } from "../Paper";

export const Root = styled(Paper)<{ state: "primary" | "secondary" | "error" }>`
  ${({ theme, ...props }) => `
    color: ${
      props.state === "primary"
        ? theme.palette.text.main
        : theme.palette[props.state].main
    };
    padding: ${theme.spacing(2)}px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-left: 5px solid ${theme.palette[props.state].main};
  `}
`;

export const Text = styled(Typography)`
  ${({ theme }) => `
    flex: 1;
    margin-left: ${theme.spacing(1)}px;
    color: inherit;
  `}
`;
