import React from "react";
import TypographyComponent, {
  TypographyProps
} from "@material-ui/core/Typography";
import { DisabledTypography } from "./style";

interface Props extends TypographyProps<any> {
  disabled?: boolean;
}

export const Typography: React.FC<Props> = ({
  disabled,
  children,
  ...props
}) => {
  if (disabled) {
    return <DisabledTypography {...props}>{children}</DisabledTypography>;
  }

  return <TypographyComponent {...props}>{children}</TypographyComponent>;
};
