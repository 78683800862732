import MenuIcon from "@material-ui/icons/MenuOutlined";
import React from "react";
import { Logo } from "../../components/Logo";
import { RouteOptions } from "../../utils/routes";
import { Nav, NavAttributes } from "./Nav";
import { Bar, BarWrapper, LogoLink, Main, MenuButton, Root } from "./style";

interface SidebarProps {
  children: React.ReactNode;
  items: NavAttributes[];
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Root open={open}>
      <Bar position="static" open={open} square={true} elevation={0}>
        <BarWrapper>
          <MenuButton
            // Icon={MenuIcon}
            aria-label="open menu"
            onClick={() => setOpen(true)}
            // size="medium"
            open={open}
          >
            <MenuIcon fontSize="inherit" color="inherit" />
          </MenuButton>

          <LogoLink href={RouteOptions.Home}>
            {![RouteOptions.Home, RouteOptions.Roles].includes(
              window.location.pathname as RouteOptions
            ) && <Logo color="light" />}
          </LogoLink>
        </BarWrapper>
      </Bar>

      <Nav open={open} items={props.items} onClose={() => setOpen(false)} />

      <Main open={open} onClick={() => setOpen(false)}>
        {props.children}
      </Main>
    </Root>
  );
};
