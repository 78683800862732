import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { RouteOptions } from "../../utils/routes";
import { CookieContent, CookieLink, CookieWrapper, Root } from "./style";
import { ButtonIcon } from "../ButtonIcon";

function getCookie(name: string) {
  const value = "; " + document.cookie;
  const parts: string[] = value.split("; " + name + "=");
  return parts?.pop()?.split(";").shift() ?? null;
}

export const CookieBanner: React.FC = (props) => {
  const [cookieBanner, setCookieBanner] = React.useState(
    Boolean(getCookie("cookie_accepted"))
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={!cookieBanner}
      autoHideDuration={6000}
    >
      <Root>
        <CookieWrapper>
          <CookieContent component="p" color="inherit" align="center">
            By continuing to use our site, you're giving consent to the{" "}
            <CookieLink href={RouteOptions.Privacy}>use of cookies</CookieLink>{" "}
          </CookieContent>
        </CookieWrapper>
        <ButtonIcon
          Icon={CloseIcon}
          aria-label="close"
          onClick={() => {
            document.cookie = `cookie_accepted=true`;
            setCookieBanner(true);
          }}
        />
      </Root>
    </Snackbar>
  );
};
