import Icon from "@material-ui/icons/CloseOutlined";
import Chip from "@material-ui/core/Chip";
import styled, { css } from "styled-components";
import { SelectButtonMixin, SelectButtonProps } from "../style";

const HoverStyle = css`
  ${({ theme }) => `
    &:focus {
      background: ${theme.palette.primary.light};
    }
    &:hover {
      background: ${theme.palette.primary.dark};
    }
  `}
`;

export const Pill = styled(Chip)<SelectButtonProps>`
  ${HoverStyle}
  ${SelectButtonMixin}

  ${({ theme }) => `
    font-weight: bold;
    padding: ${theme.spacing(0.5)}px;
  `}

`;

const IconMixin = css`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
    height: 16px;
    &:hover {
      color: ${theme.palette.secondary.main};
    }
  `}
`;

export const SelectIcon = styled(Icon)`
  ${IconMixin}
  opacity: .2;
`;

export const DeleteIcon = styled(Icon)`
  ${IconMixin}
`;
